import { ExportOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  EXPORT_TYPES,
  MODULES,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import Portal from '../../../../../components/Portal';
import PaymentLinksTable from '../components/PaymentLinksTable';
import { REQUEST_PAYMENT_LINKS_EXPORT } from '../graphql/Mutations';

const PaymentLinks = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [exportFilter, setExportFilter] = useState({});
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [requestPaymentLinksExport] = useMutation(
    REQUEST_PAYMENT_LINKS_EXPORT,
    {
      onError: () => {},
    },
  );

  const handleExport = async (values) => {
    setIsExportLoading(true);
    const res = await requestPaymentLinksExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.PAYMENT_LINKS,
        },
        filter: {
          search: exportFilter?.search,
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
          expiredAtDate: {
            from: exportFilter?.expiredAt?.from?.$d,
            to: exportFilter?.expiredAt?.to?.$d,
          },
          ...(exportFilter?.status?.length > 0 && {
            status: exportFilter?.status,
          }),
        },
      },
    });
    if (res?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsExportLoading(false);
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.PAYMENT_LINKS}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.OFFERS_GIFT_CARD,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() =>
              history?.push(`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}/add`)
            }
          >
            Create Payment Link
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.PAYMENT_LINKS,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() =>
              history?.push(`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}/add`)
            }
          >
            Create Payment Link
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <ExportDateRangeModal
        form={form}
        onFinish={handleExport}
        loadings={isExportLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Card className="full-height-without-nav">
        <PaymentLinksTable
          exportFilter={exportFilter}
          setExportFilter={setExportFilter}
        />
      </Card>
    </>
  );
};

export default PaymentLinks;
