import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../../../components/LoaderComponent';
import TransferCard from './TransferCard';

const TransferList = ({
  orderList,
  transferTypeIn,
  infiniteScrollRef,
  setIsRequestPrompts,
  setIsConfirmReturnPrompts,
  isEmptyOrderList,
  isOrderLoading,
  setOrder,
  setIsNotePrompts,
  setIsTransferOrderItemModalOpen,
  isFetchMoreOrderLoading,
  onMobileSelectChange,
  setSelectedRowKeys,
  selectedRowKeys,
  handleFulfillStatus,
  disableMultiSelect,
  setIsReportDamageModalOpen,
}) => (
  <div>
    {!isEmptyOrderList &&
      orderList?.map((order) => (
        <TransferCard
          key={order?.id}
          order={order}
          transferTypeIn={transferTypeIn}
          setIsRequestPrompts={setIsRequestPrompts}
          setIsConfirmReturnPrompts={setIsConfirmReturnPrompts}
          infiniteScrollRef={infiniteScrollRef}
          setOrder={setOrder}
          setIsNotePrompts={setIsNotePrompts}
          setIsTransferOrderItemModalOpen={setIsTransferOrderItemModalOpen}
          onMobileSelectChange={onMobileSelectChange}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          handleFulfillStatus={handleFulfillStatus}
          disableMultiSelect={disableMultiSelect}
          setIsReportDamageModalOpen={setIsReportDamageModalOpen}
        />
      ))}{' '}
    {(isFetchMoreOrderLoading || isOrderLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyOrderList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default TransferList;
