import { gql } from '@apollo/client';

export const CREATE_PAYMENT_LINK = gql`
  mutation CreatePaymentLink($input: CreatePaymentLinkInput!) {
    createPaymentLink(input: $input) {
      id
      title
      description
      amount
      paymentProvider
      paymentLink
      status
      expiryDate
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PAYMENT_LINK = gql`
  mutation UpdatePersonalizedOffer(
    $where: OfferUniqueInput!
    $data: UpdatePersonalizedOfferInput!
  ) {
    updatePersonalizedOffer(where: $where, data: $data) {
      data {
        id
        actualPrice
        offerPrice
        expiryDate
        paymentStatus
        plan {
          id
          name
        }
        isRedeemed
      }
      message
    }
  }
`;

export const REQUEST_PAYMENT_LINKS_EXPORT = gql`
  mutation RequestPaymentLinksExport(
    $data: ExportInput!
    $filter: PaymentLinksFilterInput
  ) {
    requestExport(data: $data, filter: $filter) {
      id
      type
      status
      url
      createdAt
    }
  }
`;
