import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIBRARIES = gql`
  query Libraries($filter: LibraryFilter, $sort: LibrarySort) {
    libraries(filter: $filter, sort: $sort) {
      count
      data {
        types
        referralCode
        availableBalance
        isActive
        isArchived
        address
        latLong {
          coordinates
        }
        bulkUpdateCount
        backUpLibrary {
          name
          id
          libraryNumber
        }
        warehouse {
          name
          id
          libraryNumber
        }
        deliveryPartner {
          id
          lastName
          firstName
          userNumber
        }
        city
        id
        librarian {
          firstName
          lastName
          id
          contactNo
          email
          pincode {
            id
            pincode
          }
        }
        name
        pincodes {
          pincode
          id
        }
        preferredTimeFrom
        preferredTimeTo
        state
        quantity
        createdAt
        totalToys
        libraryNumber
      }
    }
  }
`;

export const LIBRARY_ADMIN = gql`
  query libraryAdmin($where: LibraryUniqueInput!) {
    libraryAdmin(where: $where) {
      referralCode
      availableBalance
      isActive
      isArchived
      createdAt
      address
      city
      id
      libraryNumber
      latLong {
        coordinates
        type
      }
      librarian {
        contactNo
        lastName
        firstName
        email
        pincode {
          pincode
        }
        id
      }
      name
      pincodes {
        pincode
        id
      }
      preferredTimeFrom
      preferredTimeTo
      state
      pincode
      backUpLibrary {
        id
        libraryNumber
        name
        preferredTimeFrom
        preferredTimeTo
        pincode
        librarian {
          firstName
          lastName
          email
          contactNo
        }
      }
      serviceableArea
      types
    }
  }
`;

export const PINCODES = gql`
  query Pincodes($filter: PincodesFilter, $sort: PincodesSort) {
    pincodes(filter: $filter, sort: $sort) {
      data {
        id
        createdBy
        createdAt
        isActive
        pincode
        updatedAt
      }
    }
  }
`;

export const LIBRARY_INVENTORIES_ADMIN = gql`
  query libraryInventoriesAdmin(
    $where: LibraryUniqueInput!
    $filter: LibraryInventoryFilter
    $sort: LibraryInventorySort
  ) {
    libraryInventoriesAdmin(where: $where, filter: $filter, sort: $sort) {
      count
      totalRentedOut
      totalAvailableStock
      data {
        id
        createdAt
        isActive
        rentedOut
        damagedStock
        totalStock
        transferOut
        availableStock
        library {
          libraryNumber
        }
        toy {
          id
          name
          isArchived
          toyNumber
          dimensions
          type
          images {
            key
            url
          }
          brand {
            id
            name
          }
          categories {
            id
            name
          }
          facilitates {
            id
            name
          }
          tags {
            id
            name
          }
          ageGroups {
            id
            maxAge
            minAge
          }
          material {
            id
            name
          }
        }
        updatedAt
      }
    }
  }
`;

export const TOYS_FOR_LIBRARY_INVENTORY = gql`
  query ToysForLibraryInventory(
    $where: LibraryUniqueInput!
    $filter: ToysFilterForLibraryInventory
    $sort: ToysSort
  ) {
    toysForLibraryInventory(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        id
        name
        toyNumber
      }
    }
  }
`;

export const GET_SIGNED_BULK_UPDATE_URL_ADMIN = gql`
  query GetBulkUpdateSignedPutUrlAdmin(
    $data: GetBulkUpdateSignedPutUrlInputAdmin
  ) {
    getBulkUpdateSignedPutUrlAdmin(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const GET_BULK_CREATE_LIBRARY_INVENTORY_SIGNED_PUT_URL_ADMIN = gql`
  query GetBulkCreateLibraryInventorySignedPutUrlAdmin(
    $data: GetBulkUpdateSignedPutUrlInputAdmin!
  ) {
    getBulkCreateLibraryInventorySignedPutUrlAdmin(data: $data) {
      fileName
      signedUrl
    }
  }
`;
