import { Card } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES } from '../../../../../common/constants';

const TransferItemCard = ({ record }) => {
  const {
    state: {
      commonPermissions: { isUserPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  return (
    <>
      <Card
        className="ml-12 mr-12 mb-24 mt-12 card-with-shadow"
        title={
          <div className="overflow-scroll no-scroll-bar">
            {record?.transferOrderNumber}
          </div>
        }
      >
        <div className="mb-8">
          <span className="mobile-card-label">Creator Name: </span>
          <span
            onClick={() =>
              isUserPermission &&
              record?.creator?.id &&
              history?.push(
                `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.creator?.id}/${record?.creator?.userNumber}`,
              )
            }
            className={`${
              isUserPermission && record?.creator?.id && 'pointer table-cell'
            }`}
          >
            {' '}
            <>
              {record?.creator?.firstName || record?.creator?.lastName ? (
                <div className="table-data-with-id">{`${
                  record?.creator?.firstName ? record?.creator?.firstName : ''
                } ${
                  record?.creator?.lastName ? record?.creator?.lastName : ''
                }`}</div>
              ) : (
                'No Creator'
              )}
              {record?.creator?.userNumber ? (
                <div className="font-size-12">
                  ({record?.creator?.userNumber})
                </div>
              ) : (
                ''
              )}
            </>
          </span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Action User Name: </span>
          <span
            onClick={() =>
              isUserPermission &&
              record?.actionUser?.id &&
              history?.push(
                `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.actionUser?.id}/${record?.actionUser?.userNumber}`,
              )
            }
            className={`${
              isUserPermission && record?.actionUser?.id && 'pointer table-cell'
            }`}
          >
            {' '}
            <>
              {record?.actionUser?.firstName || record?.actionUser?.lastName ? (
                <div className="table-data-with-id">{`${
                  record?.actionUser?.firstName
                    ? record?.actionUser?.firstName
                    : ''
                } ${
                  record?.actionUser?.lastName
                    ? record?.actionUser?.lastName
                    : ''
                }`}</div>
              ) : (
                'No Action User'
              )}
              {record?.actionUser?.userNumber ? (
                <div className="font-size-12">
                  ({record?.actionUser?.userNumber})
                </div>
              ) : (
                ''
              )}
            </>
          </span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Request Generator: </span>
          <span
            onClick={() =>
              isUserPermission &&
              record?.requestGenerator?.id &&
              history?.push(
                `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.requestGenerator?.id}/${record?.requestGenerator?.userNumber}`,
              )
            }
            className={`${
              isUserPermission &&
              record?.requestGenerator?.id &&
              'pointer table-cell'
            }`}
          >
            {' '}
            <>
              {record?.requestGenerator?.firstName ||
              record?.requestGenerator?.lastName ? (
                <div className="table-data-with-id">{`${
                  record?.requestGenerator?.firstName
                    ? record?.requestGenerator?.firstName
                    : ''
                } ${
                  record?.requestGenerator?.lastName
                    ? record?.requestGenerator?.lastName
                    : ''
                }`}</div>
              ) : (
                'No Request Generator'
              )}
              {record?.requestGenerator?.userNumber ? (
                <div className="font-size-12">
                  ({record?.requestGenerator?.userNumber})
                </div>
              ) : (
                ''
              )}
            </>
          </span>
        </div>
      </Card>
    </>
  );
};

export default TransferItemCard;
