import { Badge, Button, Card, Checkbox, Dropdown, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as MoreIcon } from '../../../../../assets/svg/ellipsis-vertical-solid.svg';
import {
  DATE_FORMATS,
  FULFILLMENT_STATUS,
  LIBRARY_FACILITY_TYPE,
  NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS,
  ORDER_STATUS,
  ORDER_TYPES,
  RETURN_ORDER_ITEM_STATUS,
  RETURN_ORDER_TABLE_ACTIONS_OPTIONS,
  ROUTES,
  SENT_ORDER_LABEL,
  TRANSFER_ORDER_STATUS,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import SelectComponent from '../../../../../components/SelectComponent';

const TransferCard = ({
  order,
  infiniteScrollRef,
  setOrder,
  setIsNotePrompts,
  transferTypeIn,
  setIsTransferOrderItemModalOpen,
  setIsRequestPrompts,
  setIsConfirmReturnPrompts,
  onMobileSelectChange,
  selectedRowKeys,
  handleFulfillStatus,
  disableMultiSelect,
  setIsReportDamageModalOpen,
}) => {
  const {
    state: {
      currentUser,
      commonPermissions: { isLibraryPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const menuProps = {
    items: NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS?.map((item) => ({
      label: item?.label,
      key: item?.value,
    })),
    onClick: (value) => handleFulfillStatus(value?.key, order),

    disabled:
      order?.status !== TRANSFER_ORDER_STATUS?.PENDING?.value ||
      order?.type !== ORDER_TYPES.ORDER,
  };

  return (
    <div>
      {' '}
      <Card
        className="ml-12 mr-12 mb-24 mt-12 mobile-card"
        title={
          <div className="overflow-scroll no-scroll-bar">
            <Checkbox
              disabled={
                transferTypeIn
                  ? ![TRANSFER_ORDER_STATUS?.SENT?.value]?.includes(
                      order?.status,
                    ) ||
                    !order?.senderLibrary ||
                    order?.type === disableMultiSelect
                  : ![TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value]?.includes(
                      order?.status,
                    ) || order?.type === disableMultiSelect
              }
              className="mr-16"
              onChange={(e) => {
                e.stopPropagation();
                onMobileSelectChange(order?.id, order);
              }}
              checked={selectedRowKeys?.includes(order?.id)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            {order?.transferOrderNumber}
          </div>
        }
        extra={
          <div className="d-flex align-center">
            <Badge
              color={ORDER_STATUS?.[order?.orderItem?.order?.status]?.color}
              text={ORDER_STATUS?.[order?.orderItem?.order?.status]?.text}
              className="mr-16 ml-16"
            />

            <div onClick={(e) => e?.stopPropagation()} className="d-flex">
              <Dropdown
                getPopupContainer={(trigger) => trigger?.parentNode}
                menu={menuProps}
                trigger="click"
                value={
                  order?.status ===
                    TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value ||
                  order?.status === TRANSFER_ORDER_STATUS?.SENT?.value ||
                  order?.status === TRANSFER_ORDER_STATUS?.RECEIVED?.value
                    ? FULFILLMENT_STATUS.FULFILLED.value
                    : null
                }
              >
                <MoreIcon
                  className="pointer mobile-svg-icon"
                  onClick={(e) => {
                    e?.stopPropagation();
                  }}
                />
              </Dropdown>
            </div>
          </div>
        }
        ref={infiniteScrollRef}
        onClick={() => {
          setOrder(order);
          setIsTransferOrderItemModalOpen(true);
        }}
      >
        <div>
          <div className="mb-8">
            <span className="mobile-card-label">Product Name: </span>
            <span>{order?.orderItem?.toy?.name || 'No Product Name'}</span>
          </div>
          <div className="mb-8">
            <span className="mobile-card-label">Order Type: </span>
            <span>{order?.type || '-'}</span>
          </div>
          <div className="mb-8">
            <span className="mobile-card-label">Order ID: </span>
            <span>{order?.orderItem?.order?.orderNumber || 'No Order ID'}</span>
          </div>
          <div className="mb-8">
            <span className="mobile-card-label">Return Order ID: </span>
            <span>
              {order?.returnOrderItem?.returnOrder?.returnOrderNumber ||
                'No Return Order ID'}
            </span>
          </div>
          <div className="mb-8">
            <span className="mobile-card-label">Fulfillment Status: </span>
            <span className="ml-8">
              {order?.orderItem?.order?.status ? (
                <Badge
                  color={FULFILLMENT_STATUS?.[order?.orderItem?.status]?.color}
                  text={FULFILLMENT_STATUS?.[order?.orderItem?.status]?.text}
                />
              ) : (
                'No Fulfillment Status'
              )}
            </span>
          </div>
          <div className="mb-8">
            <span className="mobile-card-label">Retun Order Status: </span>
            <span>
              {RETURN_ORDER_ITEM_STATUS?.[order?.returnOrderItem?.status]
                ?.label || 'No Retun Order Status'}
            </span>
          </div>

          <div className="mb-8">
            <span className="mobile-card-label">Owner Library Name: </span>
            <span>
              {order?.senderLibrary?.id ? (
                <div
                  className={`${isLibraryPermission && 'pointer'}`}
                  onClick={() =>
                    isLibraryPermission &&
                    history?.push(
                      `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${order?.senderLibrary?.id}/${order?.senderLibrary?.libraryNumber}`,
                    )
                  }
                >
                  <div className="table-data-with-id">
                    {order?.senderLibrary?.name}
                  </div>
                  <div className="font-size-12">
                    ({order?.senderLibrary?.libraryNumber})
                  </div>
                </div>
              ) : (
                'No Owner Library'
              )}
            </span>
          </div>
          <div className="mb-8">
            <span className="mobile-card-label">Receiving Library Name: </span>
            <span>
              {order?.receiverLibrary?.id ? (
                <div
                  className={`${isLibraryPermission && 'pointer'}`}
                  onClick={() =>
                    isLibraryPermission &&
                    history?.push(
                      `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${order?.receiverLibrary?.id}/${order?.receiverLibrary?.libraryNumber}`,
                    )
                  }
                >
                  <div className="table-data-with-id">
                    {order?.receiverLibrary?.name}
                  </div>
                  <div className="font-size-12">
                    ({order?.receiverLibrary?.libraryNumber})
                  </div>
                </div>
              ) : (
                'No Receiving Library'
              )}
            </span>
          </div>
          <div className="mb-8">
            <span className="mobile-card-label">Created At: </span>
            <span>{formatDate(order?.createdAt, DATE_FORMATS?.WITH_TIME)}</span>
          </div>

          <div className="mb-8">
            <span className="mobile-card-label">Damage Requested On: </span>
            <span>
              {order?.metaData?.requestedOn
                ? formatDate(
                    order?.metaData?.requestedOn,
                    DATE_FORMATS?.WITH_TIME,
                  )
                : 'No data'}
            </span>
          </div>
          <div className="mb-8">
            <span className="mobile-card-label">Transfer Status: </span>
            <span>
              {
                // eslint-disable-next-line no-nested-ternary
                order?.status === TRANSFER_ORDER_STATUS.SENT.value
                  ? order?.type === ORDER_TYPES.ORDER &&
                    !order?.requestGenerator?.id
                    ? SENT_ORDER_LABEL
                    : TRANSFER_ORDER_STATUS?.[order?.status]?.label
                  : TRANSFER_ORDER_STATUS?.[order?.status]?.label
              }
            </span>
          </div>

          <div className="mb-8">
            <span className="mobile-card-label">Damage Note: </span>
            <span>
              <Tooltip title="View note" placement="top" zIndex={4}>
                <CommentIcon
                  className="mr-16 ml-8 pointer svg-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsNotePrompts(true);
                    setOrder(order);
                  }}
                />
              </Tooltip>{' '}
            </span>
          </div>
        </div>
        <>
          <div className="dashed-line" />
          <div className="d-flex justify-between flex-wrap">
            <div className="action-button">
              {
                // eslint-disable-next-line no-nested-ternary
                !transferTypeIn ? (
                  <>
                    <Button
                      type="primary"
                      size="small"
                      className="table-action-primary-btn"
                      onClick={(e) => {
                        e?.stopPropagation();
                        setOrder(order);
                        setIsRequestPrompts(true);
                      }}
                      disabled={
                        ![
                          TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value,
                        ]?.includes(order?.status)
                      }
                    >
                      Sent
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="primary"
                      size="small"
                      className="table-action-primary-btn"
                      onClick={(e) => {
                        e?.stopPropagation();
                        setOrder(order);
                        setIsConfirmReturnPrompts(true);
                      }}
                      disabled={
                        ![TRANSFER_ORDER_STATUS?.SENT?.value]?.includes(
                          order?.status,
                        ) || !order?.senderLibrary
                      }
                    >
                      Received
                    </Button>
                    <div>
                      <SelectComponent
                        size="small"
                        className="ml-12"
                        style={{ width: '150px' }}
                        placeholder="Select Action"
                        showAction={['click']}
                        showSearch={false}
                        value={
                          // kept static value because there's going to only one option
                          order?.metaData?.isActualDamageRequestGenerated
                            ? 'REPORT_DAMAGE'
                            : null
                        }
                        options={RETURN_ORDER_TABLE_ACTIONS_OPTIONS?.filter(
                          (option) => option?.value === 'REPORT_DAMAGE',
                        )}
                        onChange={() => {
                          setOrder(order);
                          setIsReportDamageModalOpen(true);
                        }}
                        onClick={(e) => e?.stopPropagation()}
                        disabled={
                          order?.metaData?.isActualDamageRequestGenerated ||
                          order?.type !== ORDER_TYPES.ORDER ||
                          !order?.requestGenerator?.id ||
                          order?.orderItem?.status !==
                            FULFILLMENT_STATUS.UNFULFILLED?.value ||
                          order?.status !==
                            TRANSFER_ORDER_STATUS.RECEIVED?.value ||
                          !order?.receiverLibrary?.types?.includes(
                            LIBRARY_FACILITY_TYPE.WAREHOUSE.value,
                          ) ||
                          currentUser?.libraryId !== order?.receiverLibrary?.id
                        }
                        allowClear={false}
                      />
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </>
      </Card>
    </div>
  );
};

export default TransferCard;
