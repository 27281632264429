import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import ContentWrapper from '../modules/appContent';
import BannerWrapper from '../modules/appContent/banner';
import CollectionsWrapper from '../modules/appContent/collection';
import GenresWrapper from '../modules/appContent/genres';
import StorysWrapper from '../modules/appContent/story';
import ConfigsWrapper from '../modules/configs';
import DashboardWrapper from '../modules/dashboard';
import ExportsWrapper from '../modules/exports';
import HomeVideoWrapper from '../modules/homeVideo';
import ListsWrapper from '../modules/lists';
import AgeGroupWrapper from '../modules/lists/modules/ageGroup';
import BrandWrapper from '../modules/lists/modules/brand';
import CategoryWrapper from '../modules/lists/modules/category';
import ContentMasterWrapper from '../modules/lists/modules/contentMaster';
import FacilitatesWrapper from '../modules/lists/modules/facilitates';
import ListsLibraryWrapper from '../modules/lists/modules/library';
import MaterialWrapper from '../modules/lists/modules/material';
import PackagingMaterialWrapper from '../modules/lists/modules/packagingMaterial';
import PincodeWrapper from '../modules/lists/modules/pincode';
import PincodeMappingWrapper from '../modules/lists/modules/pincodeMapping';
import RackMasterWrapper from '../modules/lists/modules/rackMaster';
import TagsWrapper from '../modules/lists/modules/tags';
import ToyWrapper from '../modules/lists/modules/toy';
import NotificationsWrapper from '../modules/notifications';
import OffersWrapper from '../modules/offers';
import CouponWrapper from '../modules/offers/modules/coupon';
import GiftCardWrapper from '../modules/offers/modules/giftCard';
import PaymentLinksWrapper from '../modules/offers/modules/paymentLinks';
import ReferralWrapper from '../modules/offers/modules/referral';
import OrdersWrapper from '../modules/orders';
import AllOrdersWrapper from '../modules/orders/modules/all';
import NewOrdersWrapper from '../modules/orders/modules/new';
import TranserOrdersWrapper from '../modules/orders/modules/transfer';
import PaymentsWrapper from '../modules/payments';
import PaymentEndUserWrapper from '../modules/payments/modules/endUser';
import PaymentLibraryWrapper from '../modules/payments/modules/library';
import ProfileWrapper from '../modules/profile';
import ReportWrapper from '../modules/reports';
import BulkWrapper from '../modules/reports/modules/bulk';
import CustomReportsWrapper from '../modules/reports/modules/customReport';
import HoldingPeriodWrapper from '../modules/reports/modules/holding';
import UnfulfilledOrderWrapper from '../modules/reports/modules/unfulfilled';
import RequestsWrapper from '../modules/requests';
import EndUserWrapper from '../modules/requests/modules/endUser';
import LibraryWrapper from '../modules/requests/modules/library';
import SubscriptionWrapper from '../modules/subscription';
import EstateWrapper from '../modules/subscription/modules/estate';
import ForestWrapper from '../modules/subscription/modules/forest';
import GardenWrapper from '../modules/subscription/modules/garden';
import NewPlansWrapper from '../modules/subscription/modules/newPlan';
import TrialWrapper from '../modules/subscription/modules/trial';
import UiLabelsWrapper from '../modules/uiLabels';
import UsersWrapper from '../modules/users';
import AdminsWrapper from '../modules/users/modules/admins';
import DeliveryWrapper from '../modules/users/modules/delivery';
import EndUsersWrapper from '../modules/users/modules/endUsers';

const ContentRoutes = () => (
  <Switch>
    <Route path={ROUTES?.MAIN} exact component={DashboardWrapper} />
    <Route
      path={`${ROUTES?.REQUESTS}${ROUTES?.END_USER}`}
      component={EndUserWrapper}
    />
    <Route
      path={`${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}`}
      component={LibraryWrapper}
    />
    <Route
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`}
      component={AdminsWrapper}
    />
    <Route
      path={`${ROUTES?.PAYMENTS}${ROUTES?.LIBRARY}`}
      component={PaymentLibraryWrapper}
    />
    <Route
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`}
      component={DeliveryWrapper}
    />
    <Route
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`}
      component={EndUsersWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`}
      component={ToyWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`}
      component={ListsLibraryWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.PINCODE_MAPPING}`}
      component={PincodeMappingWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.BRAND}`}
      component={BrandWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.MATERIAL}`}
      component={MaterialWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.CATEGORY}`}
      component={CategoryWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.AGE_GROUP}`}
      component={AgeGroupWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.FACILITATES}`}
      component={FacilitatesWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.CONTENT_MASTER}`}
      component={ContentMasterWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.RACK_MASTER}`}
      component={RackMasterWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.TAGS}`}
      component={TagsWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.PACKAGING_MATERIAL}`}
      component={PackagingMaterialWrapper}
    />
    <Route
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.GENRES}`}
      component={GenresWrapper}
    />
    <Route
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.BANNER}`}
      component={BannerWrapper}
    />

    <Route
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.COLLECTION}`}
      component={CollectionsWrapper}
    />
    <Route
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.STORY}`}
      component={StorysWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.PINCODE}`}
      component={PincodeWrapper}
    />
    <Route
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.TRIAL}`}
      component={TrialWrapper}
    />
    <Route
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.NEW_PLANS}`}
      component={NewPlansWrapper}
    />
    <Route
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}`}
      component={GardenWrapper}
    />
    <Route
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}`}
      component={EstateWrapper}
    />
    <Route
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}`}
      component={ForestWrapper}
    />
    <Route
      path={`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`}
      component={GiftCardWrapper}
    />
    <Route
      path={`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`}
      component={PaymentLinksWrapper}
    />
    <Route
      path={`${ROUTES?.OFFERS}${ROUTES?.COUPON}`}
      component={CouponWrapper}
    />
    <Route
      path={`${ROUTES?.OFFERS}${ROUTES?.REFERRAL}`}
      component={ReferralWrapper}
    />
    <Route
      path={`${ROUTES?.REPORTS}${ROUTES?.BULK_UPDATE}`}
      component={BulkWrapper}
    />
    <Route
      path={`${ROUTES?.ORDERS}${ROUTES?.NEW}`}
      component={NewOrdersWrapper}
    />
    <Route
      path={`${ROUTES?.ORDERS}${ROUTES?.ALL}`}
      component={AllOrdersWrapper}
    />
    <Route
      path={`${ROUTES?.ORDERS}${ROUTES?.TRANSFER}`}
      component={TranserOrdersWrapper}
    />
    <Route
      path={`${ROUTES?.PAYMENTS}${ROUTES?.END_USER}`}
      component={PaymentEndUserWrapper}
    />
    <Route
      path={`${ROUTES?.REPORTS}${ROUTES?.HOLDING_PERIOD}`}
      component={HoldingPeriodWrapper}
    />
    <Route
      path={`${ROUTES?.REPORTS}${ROUTES?.UNFULFILLED_ORDERS}`}
      component={UnfulfilledOrderWrapper}
    />
    <Route
      path={`${ROUTES?.REPORTS}${ROUTES?.CUSTOM_REPORTS}`}
      component={CustomReportsWrapper}
    />
    <Route path={ROUTES?.EXPORTS} exact component={ExportsWrapper} />
    <Route
      path={ROUTES?.NOTIFICATIONS}
      exact
      component={NotificationsWrapper}
    />
    <Route path={ROUTES?.SYSTEM_USERS} exact component={UsersWrapper} />
    <Route
      path={ROUTES?.SUBSCRIPTION_PLANS}
      exact
      component={SubscriptionWrapper}
    />
    <Route path={ROUTES?.REQUESTS} exact component={RequestsWrapper} />
    <Route path={ROUTES?.REPORTS} exact component={ReportWrapper} />
    <Route path={ROUTES?.ORDERS} exact component={OrdersWrapper} />
    <Route path={ROUTES?.MASTER_LISTS} exact component={ListsWrapper} />
    <Route path={ROUTES?.APP_CONTENT} exact component={ContentWrapper} />
    <Route path={ROUTES?.OFFERS} exact component={OffersWrapper} />
    <Route path={ROUTES?.PAYMENTS} exact component={PaymentsWrapper} />
    <Route path={ROUTES?.PROFILE} exact component={ProfileWrapper} />
    <Route path={ROUTES?.CONFIGS} exact component={ConfigsWrapper} />
    <Route path={ROUTES?.HOME_VIDEO} exact component={HomeVideoWrapper} />
    <Route path={ROUTES?.UI_LABELS} exact component={UiLabelsWrapper} />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ContentRoutes;
