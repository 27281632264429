import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const DASHBOARD_ADMIN = gql`
  query DashboardAdmin {
    dashboardAdmin {
      totalUsers
      totalOrdersToday
      totalCancelledOrdersToday
      totalOrders
      totalCancelledOrders
      totalActivateUsers
      subscribedUsers
    }
  }
`;

export const DASHBOARD_LIBRARY = gql`
  query DashboardLibrary {
    dashboardLibrary {
      totalOrdersToday
      totalOrders
      totalLeaveDays
      totalOrdersThisMonth
      totalCancelledOrders
      totalCancelledOrdersThisMonth
      totalCancelledOrdersToday
      totalFullfilledOrders
      totalFullfilledOrdersThisMonth
      totalFullfilledOrdersToday

      delayedPlacedOrdersCount
      delayedReturnedOrdersCount
      onboardedReferralsCount
      orderConfirmationThreshold
      returnConfirmationThreshold
      totalOrdersThisMonth
      totalDamageRequests
      totalPendingDamageRequests
      totalResolvedDamageRequests
      bagOrders {
        bagId
        lastOrderDate
        lastOrderQty
        name
        size
        totalOrderedQty
        type
      }
    }
  }
`;
