import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Image,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/svg/info-solid.svg';
import {
  COMMENT_ORDER_PHASE,
  COMMENT_TARGET_TYPE,
  DATE_FORMATS,
  DELIVERY_CONFIRMATION_TYPE,
  DELIVERY_TYPE,
  DELIVERY_TYPE_OPTIONS,
  FULFILLMENT_STATUS,
  LIMIT,
  ORDER_STATUS,
  ORDER_TYPE,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SORT,
  USER_ROLES,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import CommonPreviewTable from '../../../../../components/CommonPreviewTable';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import AddCommentModal from '../../../../../libraryModules/orders/modules/all/components/AddCommentModal';
import { USERS } from '../../../../../libraryModules/raiseRequest/graphql/Queries';
import { PINCODES } from '../../../../lists/modules/pincodeMapping/graphql/Queries';
import ResolveCommentModal from '../../../../requests/modules/library/components/ResolveCommentModal';
import { LIBRARIES } from '../../../../requests/modules/library/graphql/Queries';
import {
  CANCEL_ORDER_ADMIN,
  CREATE_COMMENT,
  RESET_ORDER_CHALLAN_ADMIN,
  RESET_ORDER_ITEM_STICKER_ADMIN,
} from '../graphql/Mutations';
import {
  GET_ORDER_CHALLAN_DOWNLOAD_URL_ADMIN,
  GET_ORDER_STICKER_DOWNLOAD_URL_ADMIN,
  ORDERS_ADMIN,
  ORDERS_SELECT,
  ORDER_ITEMS_ADMIN,
} from '../graphql/Queries';
import ContentMasterModal from './ContentMasterModal';
import ItemStatusModal from './ItemStatusModal';
import NoteModal from './NoteModal';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialNewDeliveryFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialNewDeliverySort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const AllDeliveryTable = ({
  exportFilter,
  setExportFilter,
  setSelectedOrders,
  selectedOrders,
}) => {
  const {
    state: {
      permissions,
      commonPermissions: {
        isUserPermission,
        isToyPermission,
        isDeliveryPartnerPermission,
        isLibraryPermission,
      },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const user = queryParams?.get('user') || '';
  const [orderList, setOrderList] = useState([]);
  const [orderSearchTerm, setOrderSearchTerm] = useState('');
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [order, setOrder] = useState({});
  const [orderItem, setOrderItem] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isNotePrompts, setIsNotePrompts] = useState(false);
  const [isOrderAction, setIsOrderAction] = useState(false);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const [isChallanLoading, setIsChallanLoading] = useState({});
  const [isResetLoading, setIsResetLoading] = useState({});
  const [isStickerLoading, setIsStickerLoading] = useState({});
  const [isResetStickerLoading, setIsResetStickerLoading] = useState({});
  const [isCommentPrompts, setIsCommentPrompts] = useState(false);
  const [isAddCommentModalOpen, setIsAddCommentModalOpen] = useState(false);
  const [isAddCommentLoading, setIsAddCommentLoading] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [toyPreviewImage, setToyPreviewImage] = useState();
  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false);
  const [isContentMasterModalOpen, setIsContentMasterModalOpen] = useState(
    false,
  );

  const [ordersAdmin] = useLazyQuery(ORDERS_ADMIN, {
    onCompleted: (response) => {
      setOrderList([...response?.ordersAdmin?.data]);
      if (
        response?.ordersAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOrderList(true);
      } else {
        setIsEmptyOrderList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.ordersAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsOrderLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [orderItemsAdmin] = useLazyQuery(ORDER_ITEMS_ADMIN, {
    fetchPolicy: 'no-cache',
    onError() {},
  });

  const [cancelOrderAdmin] = useMutation(CANCEL_ORDER_ADMIN, {
    onError: () => {},
  });

  const [getOrderChallanDownloadUrlAdmin] = useLazyQuery(
    GET_ORDER_CHALLAN_DOWNLOAD_URL_ADMIN,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [getOrderStickerDownloadUrlAdmin] = useLazyQuery(
    GET_ORDER_STICKER_DOWNLOAD_URL_ADMIN,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [users, { loading: isUsersLoading }] = useLazyQuery(USERS, {
    onCompleted: (response) => {
      if (response?.users?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.users?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.users?.data, (item) =>
        optionsCopy?.push({
          label: `${
            item?.firstName || item?.lastName
              ? `${item?.firstName || ''} ${item?.lastName || ''}`
              : '-'
          }`,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [pincodes, { loading: isPincodesLoading }] = useLazyQuery(PINCODES, {
    onCompleted: (response) => {
      if (response?.pincodes?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.pincodes?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.pincodes?.data, (item) =>
        optionsCopy?.push({
          label: item?.pincode,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [orderSelect, { loading: isOrderSelectLoading }] = useLazyQuery(
    ORDERS_SELECT,
    {
      onCompleted: (response) => {
        if (response?.ordersAdmin?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.ordersAdmin?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.ordersAdmin?.data, (item) =>
          optionsCopy?.push({
            label: item?.orderNumber,
            key: item?.id,
          }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [createComment] = useMutation(CREATE_COMMENT, {
    onError: () => {},
  });

  const [resetOrderChallanAdmin] = useMutation(RESET_ORDER_CHALLAN_ADMIN, {
    onError: () => {},
  });

  const [resetOrderItemStickerAdmin] = useMutation(
    RESET_ORDER_ITEM_STICKER_ADMIN,
    {
      onError: () => {},
    },
  );

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.ORDERS_ALL_DELIVERY,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setOrderSearchTerm(user);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    ordersAdmin({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          search: user || orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: ORDER_TYPE?.ALL,
        },
        sort: initialNewDeliverySort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsOrderLoading(true);
    setSortedInfo(sorter);
    setNestedData({});
    setExpandedRowKeys([]);
    setSelectedOrders([]);
    ordersAdmin({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          skip,
          limit: pagination?.pageSize,
          orderType: ORDER_TYPE?.ALL,
          search: orderSearchTerm,
          deliveryType: finalWhereFilters?.deliveryType,
          orderIds: finalWhereFilters?.orderNumber,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          pincodeIds: finalWhereFilters?.pincodes,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOrderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    ordersAdmin({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: ORDER_TYPE?.ALL,
          search: trimValue,
          deliveryType: finalWhereFilters?.deliveryType,
          orderIds: finalWhereFilters?.orderNumber,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          pincodeIds: finalWhereFilters?.pincodes,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleAddComment = async (values) => {
    setIsCommentLoading(true);
    const response = await createComment({
      variables: {
        data: {
          targetType: isOrderAction
            ? COMMENT_TARGET_TYPE?.ORDER
            : COMMENT_TARGET_TYPE?.ORDER_ITEM,
          targetId: isOrderAction ? order?.id : orderItem?.id,
          metaData: {
            orderPhase: isOrderAction
              ? COMMENT_ORDER_PHASE?.ORDER
              : COMMENT_ORDER_PHASE?.UNFULFILLED,
          },
          isResolved: true,
          comment: values?.comment?.trim(),
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsCommentPrompts(false);
      setNestedData({});
      setExpandedRowKeys([]);
      form?.resetFields();
      ordersAdmin({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: ORDER_TYPE?.ALL,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            orderIds: finalWhereFilters?.orderNumber,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            pincodeIds: finalWhereFilters?.pincodes,
            deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
            libraryIds: finalWhereFilters?.name,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsCommentLoading(false);
  };

  const handleAddCommentInOrder = async (values) => {
    setIsAddCommentLoading(true);
    const response = await cancelOrderAdmin({
      variables: {
        where: {
          id: order?.id,
        },
        data: {
          additionalComment: values?.comment?.trim(),
          reason: values?.reason,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsAddCommentModalOpen(false);
      setNestedData({});
      setExpandedRowKeys([]);
      form?.resetFields();
      ordersAdmin({
        variables: {
          filter: {
            ...initialNewDeliveryFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            orderType: ORDER_TYPE?.ALL,
            search: orderSearchTerm,
            deliveryType: finalWhereFilters?.deliveryType,
            orderIds: finalWhereFilters?.orderNumber,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            deliveredOn: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            pincodeIds: finalWhereFilters?.pincodes,
            deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
            libraryIds: finalWhereFilters?.name,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialNewDeliverySort,
        },
      });
    }
    setIsAddCommentLoading(false);
  };

  const handleChallanDownload = async (id, windowReference) => {
    setIsChallanLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: true,
    }));
    const response = await getOrderChallanDownloadUrlAdmin({
      variables: {
        where: {
          id,
        },
      },
    });
    if (response?.data) {
      // eslint-disable-next-line no-param-reassign
      windowReference.location =
        response?.data?.getOrderChallanDownloadUrlAdmin?.challanUrl;
    }
    setIsChallanLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: false,
    }));
  };

  const handleChallanReset = async (id) => {
    setIsResetLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: true,
    }));
    await resetOrderChallanAdmin({
      variables: {
        where: {
          id,
        },
      },
    });
    setIsResetLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: false,
    }));
  };

  const handleStickerDownload = async (id, windowReference) => {
    setIsStickerLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: true,
    }));
    const response = await getOrderStickerDownloadUrlAdmin({
      variables: {
        where: {
          id,
        },
      },
    });
    if (response?.data) {
      // eslint-disable-next-line no-param-reassign
      windowReference.location =
        response?.data?.getOrderStickerDownloadUrlAdmin?.stickerUrl;
    }
    setIsStickerLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: false,
    }));
  };

  const handleStickerReset = async (id) => {
    setIsResetStickerLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: true,
    }));
    await resetOrderItemStickerAdmin({
      variables: {
        where: {
          id,
        },
      },
    });
    setIsResetStickerLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: false,
    }));
  };

  const getFilterData = (confirm) => {
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    ordersAdmin({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          search: orderSearchTerm,
          orderType: ORDER_TYPE?.ALL,
          deliveryType: whereFilter?.deliveryType,
          orderIds: whereFilter?.orderNumber,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: whereFilter?.deliveredOn?.from?.$d,
            to: whereFilter?.deliveredOn?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          pincodeIds: whereFilter?.pincodes,
          deliveryPartnerIds: whereFilter?.deliveryPartnerName,
          libraryIds: whereFilter?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'deliveryType') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    ordersAdmin({
      variables: {
        filter: {
          ...initialNewDeliveryFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          orderType: ORDER_TYPE?.ALL,
          orderIds: whereFiltersCopy?.orderNumber,
          deliveryType: whereFiltersCopy?.deliveryType,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          deliveredOn: {
            from: whereFiltersCopy?.deliveredOn?.from?.$d,
            to: whereFiltersCopy?.deliveredOn?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          pincodeIds: whereFiltersCopy?.pincodes,
          deliveryPartnerIds: whereFiltersCopy?.deliveryPartnerName,
          libraryIds: whereFiltersCopy?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialNewDeliverySort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'deliveryPartnerName':
          users({
            variables: {
              filter: {
                ...initialTableFilter,
                roles: [USER_ROLES?.DELIVERY?.key],
              },
              sort: initialTableSort,
            },
          });
          break;

        case 'pincodes':
          pincodes({
            variables: {
              filter: {
                ...initialTableFilter,
              },
              sort: {
                field: 'pincode',
                order: 'ASC',
              },
            },
          });
          break;

        case 'deliveryType':
          forEach(DELIVERY_TYPE_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'status':
          forEach(ORDER_STATUS, (item) => {
            optionsCopy?.push({ label: item?.text, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'orderNumber':
          orderSelect({
            variables: {
              filter: { ...initialTableFilter, orderType: ORDER_TYPE?.ALL },
              sort: initialTableSort,
            },
          });
          break;

        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (dataIndex === 'status') {
      return;
    }
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (
      !isUsersLoading &&
      !isPincodesLoading &&
      !isOrderSelectLoading &&
      !isLibrariesLoading &&
      !filterIsEnd
    ) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'deliveryPartnerName':
              users({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    roles: [USER_ROLES?.DELIVERY?.key],
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'pincodes':
              pincodes({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: {
                    field: 'pincode',
                    order: 'ASC',
                  },
                },
              });
              break;

            case 'orderNumber':
              orderSelect({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    orderType: ORDER_TYPE?.ALL,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'deliveryPartnerName':
        users({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              roles: [USER_ROLES?.DELIVERY?.key],
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'pincodes':
        pincodes({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: {
              field: 'pincode',
              order: 'ASC',
            },
          },
        });
        break;

      case 'orderNumber':
        orderSelect({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              orderType: ORDER_TYPE?.ALL,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'deliveredOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'deliveryType') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={DELIVERY_TYPE_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const handleExpand = async (expanded, record) => {
    setIsLoading({
      [record?.id]: true,
    });
    setIsDisabled(true);
    if (!nestedData?.[record?.id]) {
      const res = await orderItemsAdmin({
        variables: {
          where: {
            id: record?.id,
          },
        },
      });
      if (res?.data) {
        setNestedData((state) => ({
          ...state,
          [record?.id]: res?.data,
        }));
      }
      setIsDisabled(false);
    }
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record?.id]);
    } else {
      setExpandedRowKeys(expandedRowKeys?.filter((key) => key !== record?.id));
    }
    setIsLoading({
      [record?.id]: false,
    });
    setIsDisabled(false);
  };

  const columns = [
    {
      title: 'ORDER ID',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      sorter: true,
      width: 150,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (_, record) => record?.orderNumber,
      ...filterPopup('orderNumber'),
    },
    {
      title: 'QUANTITY',
      dataIndex: 'quantity',
      key: 'quantity',
      ellipsis: true,
      align: 'left',
      width: 100,
      className: 'max-width-column',
      render: (_, record) => record?.orderItemCount || '-',
    },
    {
      title: 'DELIVERY TYPE',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) =>
        DELIVERY_TYPE?.[record?.deliveryType]?.label || '-',
      ...filterPopup('deliveryType'),
    },
    {
      title: 'USER NAME',
      dataIndex: 'userName',
      key: 'userName',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.user?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.user?.id}/${record?.user?.userNumber}`,
          ),
        className: `${
          isUserPermission && record?.user?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.user;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'DELIVERY PARTNER NAME',
      dataIndex: 'deliveryPartnerName',
      key: 'deliveryPartnerName',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isDeliveryPartnerPermission &&
          record?.deliveryPartner?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}/view/${record?.deliveryPartner?.id}`,
          ),
        className: `${
          isUserPermission &&
          record?.deliveryPartner?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const deliveryPartnerData = record?.deliveryPartner;
        return (
          <>
            {deliveryPartnerData?.firstName || deliveryPartnerData?.lastName ? (
              <div className="table-data-with-id">{`${
                deliveryPartnerData?.firstName
                  ? deliveryPartnerData?.firstName
                  : ''
              } ${
                deliveryPartnerData?.lastName
                  ? deliveryPartnerData?.lastName
                  : ''
              }`}</div>
            ) : (
              '-'
            )}
            {deliveryPartnerData?.userNumber ? (
              <div className="font-size-12">
                ({deliveryPartnerData?.userNumber})
              </div>
            ) : (
              ''
            )}
          </>
        );
      },
      ...filterPopup('deliveryPartnerName'),
    },
    {
      title: 'LIBRARY NAME',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      width: 300,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.library?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.library?.id}/${record?.library?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission && record?.library?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.library?.name}</div>
          <div className="font-size-12">({record?.library?.libraryNumber})</div>
        </>
      ),
      ...filterPopup('name'),
    },
    {
      title: 'PINCODE',
      dataIndex: 'pincode',
      key: 'pincode',
      ellipsis: true,
      align: 'left',
      width: 150,
      className: 'max-width-column',
      render: (_, record) => record?.user?.pincode?.pincode || '-',
      ...filterPopup('pincodes'),
    },
    {
      title: 'PLACED ON',
      dataIndex: 'placedOn',
      key: 'placedOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME),
      ...filterPopup('createdAt'),
    },
    {
      title: 'DELIVERED ON',
      dataIndex: 'deliveredOn',
      key: 'deliveredOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.deliveredOn, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('deliveredOn'),
    },
    {
      title: 'SHIPPED ATTACHMENTS',
      dataIndex: 'shippedAttachments',
      key: 'shippedAttachments',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const shippedAttachments = record?.deliveryAttachments?.filter(
          (attachment) =>
            attachment?.deliveryAttachmentsType ===
            ORDER_STATUS?.SHIPPED?.value,
        );

        const hasAttachments = shippedAttachments?.length > 0;
        const additionalCount = shippedAttachments?.length - 1;

        return (
          <span>
            {hasAttachments ? (
              <Image
                preview={{ visible: false }}
                width={20}
                height={20}
                src={shippedAttachments?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(shippedAttachments);
                }}
                className="pointer"
              />
            ) : (
              <span>No Images</span>
            )}
            {additionalCount > 0 && ` + ${additionalCount}`}
          </span>
        );
      },
    },
    {
      title: 'DELIVERED ATTACHMENTS',
      dataIndex: 'deliveredAttachments',
      key: 'deliveredAttachments',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const deliveredAttachments = record?.deliveryAttachments?.filter(
          (attachment) =>
            [ORDER_STATUS?.DELIVERED?.value, null]?.includes(
              attachment?.deliveryAttachmentsType,
            ),
        );

        const hasAttachments = deliveredAttachments?.length > 0;
        const additionalCount = deliveredAttachments?.length - 1;

        return (
          <span>
            {hasAttachments ? (
              <Image
                preview={{ visible: false }}
                width={20}
                height={20}
                src={deliveredAttachments?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(deliveredAttachments);
                }}
                className="pointer"
              />
            ) : (
              <span>No Images</span>
            )}
            {additionalCount > 0 && ` + ${additionalCount}`}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={ORDER_STATUS?.[record?.status]?.color}
          text={ORDER_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status', false),
    },
    {
      title: 'NOTE',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <div className="action-button">
          {record?.cancellationData || record?.comment?.comment ? (
            <Tooltip title="View comment" placement="top" zIndex={4}>
              <CommentIcon
                className="mr-16 pointer svg-icon"
                onClick={() => {
                  setIsNotePrompts(true);
                  setOrder(record);
                  setIsOrderAction(true);
                }}
              />
            </Tooltip>
          ) : (
            <span className="mr-24">-</span>
          )}
          {hasUpdatePermission && (
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => {
                setIsCommentPrompts(true);
                setOrder(record);
                setIsOrderAction(true);
              }}
              disabled={
                record?.comment?.isResolved ||
                record?.status !== ORDER_STATUS?.CANCELLED?.value
              }
            >
              Resolve
            </Button>
          )}
        </div>
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <>
          <div className="action-button">
            {hasUpdatePermission && (
              <div className="mr-16">
                <Button
                  type="primary"
                  size="small"
                  className="table-action-primary-btn"
                  onClick={() => {
                    setIsAddCommentModalOpen(true);
                    setOrder(record);
                    setIsOrderAction(true);
                  }}
                  disabled={
                    record?.status === ORDER_STATUS?.DELIVERED?.value ||
                    record?.status === ORDER_STATUS?.CANCELLED?.value
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
            <div className="mr-16">
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  const windowReference = window.open();
                  handleChallanDownload(record?.id, windowReference);
                }}
                loading={isChallanLoading?.[record?.id]}
                disabled={
                  ![
                    ORDER_STATUS?.READY_TO_SHIP?.value,
                    ORDER_STATUS?.SHIPPED?.value,
                    ORDER_STATUS?.CONFIRMED?.value,
                    ORDER_STATUS?.DELIVERED?.value,
                    ORDER_STATUS?.NOT_DELIVERED?.value,
                  ]?.includes(record?.status)
                }
              >
                Download Challan
              </Button>
            </div>
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => handleChallanReset(record?.id)}
              loading={isResetLoading?.[record?.id]}
              disabled={
                ![
                  ORDER_STATUS?.READY_TO_SHIP?.value,
                  ORDER_STATUS?.CONFIRMED?.value,
                ]?.includes(record?.status)
              }
            >
              Reset Challan
            </Button>
            <Tooltip title="Order Status Logs" placement="top" zIndex={4}>
              <InfoIcon
                onClick={() => {
                  setOrder(record);
                  setIsOrderAction(true);
                  setIsOrderStatusModalOpen(true);
                }}
                className="ml-16 pointer svg-icon"
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  const rowSelection = {
    selectedRowKeys: selectedOrders,
    onChange: (selectedRowKeys) => {
      setSelectedOrders(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: ![
        ORDER_STATUS?.READY_TO_SHIP?.value,
        ORDER_STATUS?.SHIPPED?.value,
        ORDER_STATUS?.CONFIRMED?.value,
        ORDER_STATUS?.DELIVERED?.value,
        ORDER_STATUS?.NOT_DELIVERED?.value,
      ]?.includes(record?.status),
    }),
  };
  const expandedRowRender = (data) => {
    const orderItemList = nestedData?.[data?.id]?.orderItemsAdmin?.data;
    const nestedColumns = [
      {
        title: 'PRODUCT NAME',
        dataIndex: 'productName',
        key: 'productName',
        ellipsis: true,
        align: 'left',
        width: 250,
        className: 'max-width-column',
        onCell: (record) => ({
          onClick: () =>
            isToyPermission &&
            record?.toy?.id &&
            history?.push(
              `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.toy?.id}`,
            ),
          className: `${
            isToyPermission && record?.toy?.id && 'pointer table-cell'
          }`,
        }),
        render: (_, record) => (
          <>
            <div className="table-data-with-id">{record?.toy?.name || '-'}</div>
            {record?.toy?.toyNumber && (
              <div className="font-size-12">({record?.toy?.toyNumber})</div>
            )}
          </>
        ),
      },
      {
        title: 'IMAGES',
        dataIndex: 'images',
        key: 'images',
        ellipsis: true,
        width: 100,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => {
          const images = record?.toy?.images;
          return (
            <span>
              {images?.length > 0 ? (
                <>
                  <Image
                    preview={{
                      visible: false,
                    }}
                    width={20}
                    height={20}
                    src={images?.[0]?.url}
                    onClick={(e) => {
                      e?.stopPropagation();
                      setVisible(true);
                      setToyImages(images);
                    }}
                    className="pointer"
                  />
                </>
              ) : (
                <span>No Images</span>
              )}
              {images?.length > 1 ? ` + ${images?.length - 1}` : ''}
            </span>
          );
        },
      },
      {
        title: 'BRAND',
        dataIndex: 'brand',
        key: 'brand',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) => record?.toy?.brand?.name || '-',
      },
      {
        title: 'PACKAGING MATERIAL',
        dataIndex: 'packagingMaterial',
        key: 'packagingMaterial',
        ellipsis: true,
        width: 250,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <>
            {record?.toy?.bag ? (
              <div>
                <span className="mr-8">
                  {record?.toy?.bag?.name} ({record?.toy?.bag?.size})
                </span>
                <Image
                  preview={{ visible: false }}
                  width={22}
                  src={record?.toy?.bag?.image}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setPreviewVisible(true);
                    setToyPreviewImage(record?.toy?.bag?.image);
                  }}
                  className="pointer"
                />
              </div>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        title: 'AGE GROUPS',
        dataIndex: 'ageGroups',
        key: 'ageGroups',
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) =>
          record?.toy?.ageGroups?.map((ageGroup) =>
            ageGroup?.minAge > 18 ? (
              <Tag key={ageGroup?.id}>
                {Math.floor(ageGroup?.minAge / 12)} -{' '}
                {Math.floor(ageGroup?.maxAge / 12)} Years
              </Tag>
            ) : (
              <Tag key={ageGroup?.id}>
                {ageGroup?.minAge} - {ageGroup?.maxAge} Months
              </Tag>
            ),
          ),
      },
      {
        title: 'FULFILLMENT STATUS',
        dataIndex: 'fulfillmentStatus',
        key: 'fulfillmentStatus',
        ellipsis: true,
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) =>
          record?.status === FULFILLMENT_STATUS?.FULFILLED?.value ||
          record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
          record?.status === FULFILLMENT_STATUS?.CANCELLED?.value ? (
            <Badge
              color={FULFILLMENT_STATUS?.[record?.status]?.color}
              text={FULFILLMENT_STATUS?.[record?.status]?.text}
            />
          ) : (
            '-'
          ),
      },
      {
        title: 'EXPECTED RETURN',
        dataIndex: 'returnDate',
        key: 'returnDate',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: 'max-width-column',
        render: (_, record) =>
          formatDate(record?.returnDate, DATE_FORMATS?.REGULAR) || '-',
      },
      {
        title: 'DELIVERY CONFIRMATION TYPE',
        dataIndex: 'deliveryConfirmationType',
        key: 'deliveryConfirmationType',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: 'max-width-column',
        render: (_, record) =>
          DELIVERY_CONFIRMATION_TYPE?.[record?.deliveryConfirmationData?.type]
            ?.label || '-',
      },
      {
        title: 'DELIVERY CONFIRMED ON',
        dataIndex: 'confirmedOn',
        key: 'confirmedOn',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: 'max-width-column',
        render: (_, record) =>
          formatDate(
            record?.deliveryConfirmationData?.confirmedAt,
            DATE_FORMATS?.REGULAR,
          ) || '-',
      },
      {
        title: 'NOTE',
        dataIndex: 'note',
        key: 'note',
        ellipsis: true,
        align: 'left',
        width: 300,
        className: 'max-width-column',
        render: (_, record) => (
          <div className="action-button">
            {record?.cancellationData || record?.comment?.comment ? (
              <Tooltip title="View comment" placement="top" zIndex={4}>
                <CommentIcon
                  className="mr-24 pointer svg-icon"
                  onClick={() => {
                    setIsNotePrompts(true);
                    setOrderItem(record);
                    setIsOrderAction(false);
                  }}
                />
              </Tooltip>
            ) : (
              <span className="mr-24">-</span>
            )}
            {hasUpdatePermission && (
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  setIsCommentPrompts(true);
                  setOrderItem(record);
                  setIsOrderAction(false);
                }}
                disabled={
                  record?.comment?.isResolved ||
                  record?.status !== FULFILLMENT_STATUS?.UNFULFILLED?.value
                }
              >
                Resolve
              </Button>
            )}
          </div>
        ),
      },
      {
        title: 'ACTIONS',
        dataIndex: 'actions',
        key: 'actions',
        ellipsis: true,
        width: 400,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => (
          <>
            <div className="action-button">
              <div className="mr-16">
                <Button
                  type="primary"
                  size="small"
                  className="table-action-primary-btn"
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    const windowReference = window.open();
                    handleStickerDownload(record?.id, windowReference);
                  }}
                  loading={isStickerLoading?.[record?.id]}
                  disabled={
                    record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
                    ![
                      ORDER_STATUS?.CONFIRMED?.value,
                      ORDER_STATUS?.READY_TO_SHIP?.value,
                    ]?.includes(data?.status)
                  }
                >
                  Download Sticker
                </Button>
              </div>
              <div className="mr-16">
                <Button
                  type="primary"
                  size="small"
                  className="table-action-primary-btn"
                  onClick={() => handleStickerReset(record?.id)}
                  loading={isResetStickerLoading?.[record?.id]}
                  disabled={
                    record?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
                    ![
                      ORDER_STATUS?.CONFIRMED?.value,
                      ORDER_STATUS?.READY_TO_SHIP?.value,
                    ]?.includes(data?.status)
                  }
                >
                  Reset Sticker
                </Button>
              </div>
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  setOrderItem(record);
                  setIsOrderAction(false);
                  setIsContentMasterModalOpen(true);
                }}
              >
                View Content Logs
              </Button>
              <Tooltip
                title="Order Item Status Logs"
                placement="top"
                zIndex={4}
              >
                <InfoIcon
                  onClick={() => {
                    setOrderItem(record);
                    setIsOrderAction(false);
                    setIsOrderStatusModalOpen(true);
                  }}
                  className="ml-16 pointer svg-icon"
                />
              </Tooltip>
            </div>
          </>
        ),
      },
    ];

    return (
      <CommonTable
        loading={isLoading[data?.id]}
        columns={nestedColumns}
        dataSource={orderItemList || []}
        pagination={false}
        rowKey={(record) => record?.id}
        className="nested-table"
      />
    );
  };

  const locale = {
    emptyText: isEmptyOrderList ? '' : <span />,
  };

  const expandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded disabled"
        aria-label="Expand row"
        aria-expanded="false"
        onClick={(e) => onExpand(record, e)}
        disabled={isDisabled}
      />
    ) : (
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
        aria-label="Collapse row"
        aria-expanded="true"
        onClick={(e) => onExpand(record, e)}
        disabled={isDisabled}
      />
    );

  const handleCancel = () => {
    setIsContentMasterModalOpen(false);
    setOrderItem({});
  };

  return (
    <>
      <ContentMasterModal
        isModalOpen={isContentMasterModalOpen}
        contentHistory={orderItem?.contentHistory}
        handleCancel={handleCancel}
      />
      <ItemStatusModal
        title={isOrderAction ? 'Order Status Logs' : 'Order Item Status Logs'}
        isModalOpen={isOrderStatusModalOpen}
        setIsModalOpen={setIsOrderStatusModalOpen}
        statusHistories={
          isOrderAction ? order?.statusHistory : orderItem?.statusHistory
        }
        isOrderAction={isOrderAction}
      />
      <ResolveCommentModal
        form={form}
        isResolved={false}
        isModalOpen={isCommentPrompts}
        setIsModalOpen={setIsCommentPrompts}
        isBtnLoading={isCommentLoading}
        onFinish={handleAddComment}
      />
      <NoteModal
        note={
          isOrderAction ? order?.cancellationData : orderItem?.cancellationData
        }
        isModalOpen={isNotePrompts}
        setIsModalOpen={setIsNotePrompts}
        isOrderAction={isOrderAction}
        adminNote={
          isOrderAction ? order?.comment?.comment : orderItem?.comment?.comment
        }
      />
      <AddCommentModal
        form={form}
        isOrderAction={isOrderAction}
        isModalOpen={isAddCommentModalOpen}
        setIsModalOpen={setIsAddCommentModalOpen}
        isBtnLoading={isAddCommentLoading}
        onFinish={handleAddCommentInOrder}
      />
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <CommonPreview
        visible={previewVisible}
        setVisible={setPreviewVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={orderSearchTerm}
          setQuery={setOrderSearchTerm}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={orderList || []}
        loading={isOrderLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        expandedRowKeys={expandedRowKeys}
        onExpand={(_, record) => setOrder(record)}
        rowSelection={rowSelection}
        expandable={{
          onExpand: (expanded, record) => handleExpand(expanded, record),
          expandedRowRender: (record) => expandedRowRender(record),
          expandIcon,
        }}
      />
    </>
  );
};

export default AllDeliveryTable;
