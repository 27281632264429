import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  Divider,
  Empty,
  Form,
  Image,
  Modal,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ReactComponent as EditIcon } from '../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as FilterIcon } from '../../../assets/svg/sliders-solid.svg';
import {
  BREAKPOINTS,
  EXPORT_TYPES,
  LIMIT,
  PRODUCT_TYPE,
  ROUTES,
  SORT,
  SORTING,
} from '../../../common/constants';
import {
  fileUpload,
  hasNonEmptyValueObj,
  openNotification,
} from '../../../common/utils';
import CommonPreview from '../../../components/CommonPreview';
import CommonPreviewTable from '../../../components/CommonPreviewTable';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import SortDropdown from '../../../components/SortDropdown';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import BulkUpdateModal from '../../../modules/lists/modules/library/components/BulkUpdateModal';
import EditInventoryModal from '../../../modules/lists/modules/library/components/EditInventoryModal';
import {
  AGE_GROUPS,
  CATEGORIES,
} from '../../../modules/lists/modules/toy/graphql/Queries';
import {
  ASSIGN_RACK,
  LIBRARY_INVENTORY_BULK_UPDATE,
  REQUEST_LIBRARY_INVENTORY_EXPORT,
  UPDATE_LIBRARY_INVENTORY,
} from '../graphql/Mutations';
import {
  BRANDS,
  FACILITATES,
  GET_SIGNED_BULK_UPDATE_URL,
  LIBRARY_INVENTORIES,
  MATERIALS,
  TAGS,
} from '../graphql/Queries';
import AllFilterModal from './AllFilterModal';
import InventoryList from './InventoryList';
import RackSelect from './RackSelect';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialToyFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialToySort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'name',
  order: 'ASC',
};

let filterScrollDebounceJob = null;

const sort = [
  {
    label: SORTING?.PRODUCT_NAME_A_Z?.label,
    value: SORTING?.PRODUCT_NAME_A_Z?.value,
    field: SORTING?.PRODUCT_NAME_A_Z?.field,
    sort: SORTING?.PRODUCT_NAME_A_Z?.sort,
  },
  {
    label: SORTING?.PRODUCT_NAME_Z_A?.label,
    value: SORTING?.PRODUCT_NAME_Z_A?.value,
    field: SORTING?.PRODUCT_NAME_Z_A?.field,
    sort: SORTING?.PRODUCT_NAME_Z_A?.sort,
  },
];

const InventoryTable = ({
  libraryId,
  isView,
  exportFilter,
  setExportFilter,
}) => {
  const {
    state: { isDesktop },
  } = useContext(AppContext);
  const [bulkUpdateForm] = Form.useForm();
  const history = useHistory();
  const [editInventoryForm] = Form.useForm();
  const [inventoryList, setInventoryList] = useState([]);
  const [inventorySearchTerm, setInventorySearchTerm] = useState('');
  const [isEmptyInventoryList, setIsEmptyInventoryList] = useState(false);
  const [inventory, setInventory] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [bulkUpdateFile, setBulkUpdateFile] = useState([]);

  const [isInventoryLoading, setIsInventoryLoading] = useState(true);
  const [isEditInventoryLoading, setIsEditInventoryLoading] = useState(false);
  const [isBulkUpdateLoading, setIsBulkUpdateLoading] = useState(false);

  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [toyPreviewImage, setToyPreviewImage] = useState();
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
  const [isEditInventoryModalOpen, setIsEditInventoryModalOpen] = useState(
    false,
  );

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isGoExportVisible, setIsGoExportVisible] = useState(false);

  const [isAllFilterModalOpen, setIsAllFilterModalOpen] = useState(false);
  const [isFetchMoreToysLoading, setIsFetchMoreToysLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isAllSortPopoverOpen, setIsAllSortPopoverOpen] = useState(false);
  const [rackData, setRackData] = useState();
  const [isAssignRackLoading, setIsAssignRackLoading] = useState(false);
  const [isAssignRackPrompts, setIsAssignRackPrompts] = useState(false);
  const [totalToysDetails, setTotalToysDetails] = useState({
    totalRentedOut: 0,
    totalAvailableStock: 0,
  });

  const [updateLibraryInventory] = useMutation(UPDATE_LIBRARY_INVENTORY, {
    onError: () => {},
  });

  const [assignRack] = useMutation(ASSIGN_RACK, {
    onError: () => {},
  });

  const [getSignUrl] = useLazyQuery(GET_SIGNED_BULK_UPDATE_URL, {
    fetchPolicy: 'network-only',
    onError() {},
  });
  const [libraryInventoryBulkUpdate] = useMutation(
    LIBRARY_INVENTORY_BULK_UPDATE,
    {
      onError: () => {},
    },
  );
  const [requestLibraryInventoryExport] = useMutation(
    REQUEST_LIBRARY_INVENTORY_EXPORT,
    {
      onError: () => {},
    },
  );

  const [libraryInventories] = useLazyQuery(LIBRARY_INVENTORIES, {
    onCompleted: (response) => {
      if (isDesktop) {
        setInventoryList([...response?.libraryInventories?.data]);
      } else {
        setInventoryList([
          ...inventoryList,
          ...response?.libraryInventories?.data,
        ]);
      }
      if (
        response?.libraryInventories?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyInventoryList(true);
      } else {
        setIsEmptyInventoryList(false);
      }
      if (response?.libraryInventories?.data?.length < LIMIT) {
        setIsActive(false);
      } else {
        setIsActive(true);
      }
      setTotalToysDetails({
        totalRentedOut: response?.libraryInventories?.totalRentedOut,
        totalAvailableStock: response?.libraryInventories?.totalAvailableStock,
      });
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.libraryInventories?.count,
      };
      setPaginationProp(pagination);
      setIsInventoryLoading(false);
      setIsFetchMoreToysLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [brands, { loading: isBrandsLoading }] = useLazyQuery(BRANDS, {
    onCompleted: (response) => {
      if (response?.brands?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.brands?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.brands?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [categories, { loading: isCategoriesLoading }] = useLazyQuery(
    CATEGORIES,
    {
      onCompleted: (response) => {
        if (response?.categories?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.categories?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.categories?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.id }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [facilitates, { loading: isFacilitateLoading }] = useLazyQuery(
    FACILITATES,
    {
      onCompleted: (response) => {
        if (response?.facilitates?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.facilitates?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.facilitates?.data, (item) =>
          optionsCopy?.push({
            label: item?.name?.replace(/\s/g, '\u00a0'),
            key: item?.id,
          }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [tags, { loading: isTagLoading }] = useLazyQuery(TAGS, {
    onCompleted: (response) => {
      if (response?.tags?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.tags?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.tags?.data, (item) =>
        optionsCopy?.push({
          label: item?.name?.replace(/\s/g, '\u00a0'),
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [materials, { loading: isMaterialsLoading }] = useLazyQuery(MATERIALS, {
    onCompleted: (response) => {
      if (response?.materials?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.materials?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.materials?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [ageGroups, { loading: isAgeGroupsLoading }] = useLazyQuery(
    AGE_GROUPS,
    {
      onCompleted: (response) => {
        if (response?.ageGroups?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.ageGroups?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.ageGroups?.data, (item) => {
          if (item?.minAge !== 0 || item?.maxAge !== 0) {
            optionsCopy?.push({
              label:
                item?.minAge > 18
                  ? `${Math.floor(item?.minAge / 12)} - ${Math.floor(
                      item?.maxAge / 12,
                    )} Years`
                  : `${item?.minAge} - ${item?.maxAge} Months`,
              key: item?.id,
            });
          }
        });
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInventoryLoading(true);
    setInventoryList([]);
    libraryInventories({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          search: inventorySearchTerm,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
          facilitateIds: finalWhereFilters?.facilitates,
          tagIds: finalWhereFilters?.tags,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  }, [libraryId, isDesktop]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsInventoryLoading(true);
    setSortedInfo(sorter);
    libraryInventories({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          skip,
          limit: pagination?.pageSize,
          search: inventorySearchTerm,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
          facilitateIds: finalWhereFilters?.facilitates,
          tagIds: finalWhereFilters?.tags,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setInventorySearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setInventoryList([]);
    setIsInventoryLoading(true);
    libraryInventories({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
          facilitateIds: finalWhereFilters?.facilitates,
          tagIds: finalWhereFilters?.tags,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleEditInventoryToys = async (values) => {
    setIsEditInventoryLoading(true);
    const response = await updateLibraryInventory({
      variables: {
        where: {
          id: inventory?.id,
        },
        data: {
          availableStock: parseInt(values?.currentlyAvailable, 10),
          reason: values?.reason?.trim(),
        },
      },
    });
    if (response?.data) {
      setInventoryList([]);
      setIsEditInventoryModalOpen(false);
      setIsInventoryLoading(true);
      editInventoryForm?.resetFields();
      libraryInventories({
        variables: {
          where: {
            id: libraryId,
          },
          filter: {
            ...initialToyFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: inventorySearchTerm,
            brandIds: finalWhereFilters?.brand,
            categoryIds: finalWhereFilters?.category,
            materialIds: finalWhereFilters?.material,
            ageGroupIds: finalWhereFilters?.ageGroup,
            facilitateIds: finalWhereFilters?.facilitates,
            tagIds: finalWhereFilters?.tags,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialToySort,
        },
      });
    }
    setIsEditInventoryLoading(false);
  };

  const handleExport = async () => {
    setIsExportLoading(true);
    const res = await requestLibraryInventoryExport({
      variables: {
        where: {
          id: libraryId,
        },
        data: {
          type: EXPORT_TYPES?.LIBRARY_INVENTORY,
        },
        filter: {
          search: exportFilter?.search,
          brandIds: exportFilter?.brand,
          categoryIds: exportFilter?.category,
          materialIds: exportFilter?.material,
          ageGroupIds: exportFilter?.ageGroup,
          facilitateIds: exportFilter?.facilitates,
          tagIds: exportFilter?.tags,
        },
      },
    });
    if (res?.data) {
      setIsGoExportVisible(true);
    }
    setIsExportLoading(false);
  };

  const handleInventoryScroll = () => {
    if (!isFetchMoreToysLoading && !isInventoryLoading) {
      const currentLength = inventoryList?.length;
      setIsFetchMoreToysLoading(true);
      libraryInventories({
        variables: {
          where: {
            id: libraryId,
          },
          filter: {
            ...initialToyFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            search: inventorySearchTerm,
            brandIds: finalWhereFilters?.brand,
            categoryIds: finalWhereFilters?.category,
            materialIds: finalWhereFilters?.material,
            ageGroupIds: finalWhereFilters?.ageGroup,
            facilitateIds: finalWhereFilters?.facilitates,
            tagIds: finalWhereFilters?.tags,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialToySort,
        },
      });
    }
  };

  const handleFilter = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInventoryLoading(true);
    setIsAllFilterModalOpen(false);
    setInventoryList([]);
    setIsActive(true);
    setWhereFilter(values);
    setFinalWhereFilters(values);
    libraryInventories({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inventorySearchTerm,
          brandIds: values?.brand,
          categoryIds: values?.category,
          materialIds: values?.material,
          ageGroupIds: values?.ageGroup,
          facilitateIds: values?.facilitates,
          tagIds: values?.tags,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  };

  const handleSort = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInventoryLoading(true);
    setIsAllSortPopoverOpen(false);
    setIsActive(true);
    setInventoryList([]);
    const filteredSort = sort?.filter((item) => item?.value === values?.sort);
    if (filteredSort?.[0]?.field) {
      setSortedInfo({
        ...sortedInfo,
        field: filteredSort?.[0]?.field,
        columnKey: filteredSort?.[0]?.field,
        order: filteredSort?.[0]?.sort === SORT?.ASC ? 'ascend' : 'descend',
        column: filteredSort?.[0]?.field,
      });
    }
    libraryInventories({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inventorySearchTerm,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
          facilitateIds: finalWhereFilters?.facilitates,
          tagIds: finalWhereFilters?.tags,
        },
        sort: filteredSort?.[0]
          ? {
              field: filteredSort?.[0]?.field,
              order: filteredSort?.[0]?.sort,
            }
          : initialToySort,
      },
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleInventoryScroll, isActive);

  const getFilterData = (confirm) => {
    setIsInventoryLoading(true);
    libraryInventories({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inventorySearchTerm,
          brandIds: whereFilter?.brand,
          categoryIds: whereFilter?.category,
          materialIds: whereFilter?.material,
          ageGroupIds: whereFilter?.ageGroup,
          facilitateIds: whereFilter?.facilitates,
          tagIds: whereFilter?.tags,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    if (!isView) {
      setExportFilter({
        ...exportFilter,
        ...finalWhereFilters,
        ...whereFilter,
      });
    }
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    if (!isView) {
      setExportFilter({
        ...exportFilter,
        ...finalWhereFilters,
        ...whereFiltersCopy,
      });
    }
    setIsInventoryLoading(true);
    libraryInventories({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inventorySearchTerm,
          brandIds: whereFiltersCopy?.brand,
          categoryIds: whereFiltersCopy?.category,
          materialIds: whereFiltersCopy?.material,
          ageGroupIds: whereFiltersCopy?.ageGroup,
          facilitateIds: whereFiltersCopy?.facilitates,
          tagIds: whereFiltersCopy?.tags,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      switch (filterIndex) {
        case 'brand':
          brands({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'category':
          categories({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'material':
          materials({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'ageGroup':
          ageGroups({
            variables: {
              filter: initialTableFilter,
              sort: {
                field: 'minAge',
                order: SORT?.ASC,
              },
            },
          });
          break;

        case 'facilitates':
          facilitates({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'tags':
          tags({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (
      !isBrandsLoading &&
      !isCategoriesLoading &&
      !isMaterialsLoading &&
      !isAgeGroupsLoading &&
      !isFacilitateLoading &&
      !isTagLoading &&
      !filterIsEnd
    ) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'brand':
              brands({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'category':
              categories({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'material':
              materials({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'ageGroup':
              ageGroups({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength + 1,
                    search: filterSearch,
                  },
                  sort: {
                    field: 'minAge',
                    order: SORT?.ASC,
                  },
                },
              });
              break;

            case 'facilitates':
              facilitates({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'tags':
              tags({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'brand':
        brands({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'category':
        categories({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'material':
        materials({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'ageGroup':
        ageGroups({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: {
              field: 'minAge',
              order: SORT?.ASC,
            },
          },
        });
        break;

      case 'facilitates':
        facilitates({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'tags':
        tags({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35} size="medium">
          {showSearch && (
            <SearchComponent
              className="filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              query={filterSearch}
              setQuery={setFilterSearch}
              getData={(value) => handleFilterSearch(value, dataIndex)}
            />
          )}
          {filterTags?.[dataIndex]?.length > 0 && (
            <div className="filter-tags-wrapper">
              {map(filterTags?.[dataIndex], (item) => (
                <Tag
                  key={item?.key}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  <span>{item?.label}</span>
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onFilterScroll(e, dataIndex)}
          >
            {filterList?.length > 0
              ? map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key}>
                    <Checkbox
                      value={item}
                      checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span>{item?.label}</span>
                    </Checkbox>
                  </div>
                ))
              : isEmptyFilterList && (
                  <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex, confirm)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const handleBulkUpdate = async (values) => {
    setIsBulkUpdateLoading(true);
    const uuid = uuidv4();
    let bulkUpdateFileName = '';

    if (!values?.bulkUpdateFile?.url) {
      const { name } = values?.bulkUpdateFile?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `bulkUpdate/${uuid}/${newFilename}`;

      const res = await getSignUrl({
        variables: {
          data: {
            fileName: fileKey,
          },
        },
      });

      if (res) {
        const { signedUrl, fileName } = res?.data?.getBulkUpdateSignedPutUrl;
        try {
          await fileUpload(
            signedUrl,
            values?.bulkUpdateFile?.[0]?.originFileObj,
          )?.catch((error) => {
            if (error === 403) {
              openNotification(
                'error',
                `${values?.bulkUpdateFile?.name
                  ?.toString()
                  ?.toUpperCase()} upload failed. Please try again.`,
              );
            }
          });
          bulkUpdateFileName = fileName;
        } catch (error) {
          setIsBulkUpdateLoading(false);
          return error;
        }
      }
    }
    try {
      const response = await libraryInventoryBulkUpdate({
        variables: { data: { key: bulkUpdateFileName } },
      });
      if (response?.data) {
        setIsInventoryLoading(true);
        setIsBulkUpdateLoading(false);
        setIsBulkUpdateModalOpen(false);
        setBulkUpdateFile([]);
        setIsGoExportVisible(false);
        libraryInventories({
          variables: {
            where: {
              id: libraryId,
            },
            filter: {
              ...initialToyFilter,
              skip:
                (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
              limit: paginationProp?.pageSize || LIMIT,
              search: inventorySearchTerm,
              brandIds: finalWhereFilters?.brand,
              categoryIds: finalWhereFilters?.category,
              materialIds: finalWhereFilters?.material,
              ageGroupIds: finalWhereFilters?.ageGroup,
            },
            sort: {
              ...initialToySort,
              field: sortedInfo?.field || 'createdAt',
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            },
          },
        });
      }
    } catch (error) {
      return error;
    }
    setIsBulkUpdateLoading(false);
  };

  const handleAssignRack = async () => {
    setIsAssignRackLoading(true);
    const response = await assignRack({
      variables: {
        where: {
          id: rackData?.value,
        },
        data: {
          libraryInventoryIds: [inventory?.id],
        },
      },
    });
    if (response?.data) {
      setIsAssignRackPrompts(false);
      setIsInventoryLoading(true);
      setInventoryList([]);
      libraryInventories({
        variables: {
          where: {
            id: libraryId,
          },
          filter: {
            ...initialToyFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: inventorySearchTerm,
            brandIds: finalWhereFilters?.brand,
            categoryIds: finalWhereFilters?.category,
            materialIds: finalWhereFilters?.material,
            ageGroupIds: finalWhereFilters?.ageGroup,
          },
          sort: {
            ...initialToySort,
            field: sortedInfo?.field || 'createdAt',
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          },
        },
      });
    }
    setIsAssignRackLoading(false);
  };

  const columns = [
    {
      title: 'PRODUCT NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.toy?.name}</div>
          <div className="font-size-12">({record?.toy?.toyNumber})</div>
        </>
      ),
    },
    {
      title: 'IMAGES',
      dataIndex: 'images',
      key: 'images',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.toy?.images?.length > 0 ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.toy?.images?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(record?.toy?.images);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Images</span>
          )}
          {record?.toy?.images?.length > 1
            ? ` + ${record?.toy?.images?.length - 1}`
            : ''}
        </span>
      ),
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => PRODUCT_TYPE?.[record?.toy?.type]?.label || '-',
    },
    {
      title: 'BRAND',
      dataIndex: 'brand',
      key: 'brand',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.toy?.brand?.name,
      ...filterPopup('brand'),
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'category',
      key: 'category',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.categories?.map((category) => (
          <Tag key={category?.id}>{category?.name}</Tag>
        )),
      ...filterPopup('category'),
    },
    {
      title: 'MATERIAL',
      dataIndex: 'material',
      key: 'material',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.toy?.material?.name,
      ...filterPopup('material'),
    },
    {
      title: 'AGE GROUP',
      dataIndex: 'ageGroup',
      key: 'ageGroup',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.ageGroups?.map((ageGroup) =>
          ageGroup?.minAge > 18 ? (
            <Tag key={ageGroup?.id}>
              {Math.floor(ageGroup?.minAge / 12)} -{' '}
              {Math.floor(ageGroup?.maxAge / 12)} Years
            </Tag>
          ) : (
            <Tag key={ageGroup?.id}>
              {ageGroup?.minAge} - {ageGroup?.maxAge} Months
            </Tag>
          ),
        ),
      ...filterPopup('ageGroup', false),
    },
    {
      title: 'PACKAGING MATERIAL',
      dataIndex: 'packagingMaterial',
      key: 'packagingMaterial',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <>
          {record?.toy?.bag ? (
            <div>
              <span className="mr-8">
                {record?.toy?.bag?.name} ({record?.toy?.bag?.size})
              </span>
              <Image
                preview={false}
                width={22}
                src={record?.toy?.bag?.image}
                onClick={(e) => {
                  e?.stopPropagation();
                  setPreviewVisible(true);
                  setToyPreviewImage(record?.toy?.bag?.image);
                }}
                className="pointer"
              />
            </div>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'FACILITATES',
      dataIndex: 'facilitates',
      key: 'facilitates',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.facilitates?.length > 0
          ? record?.toy?.facilitates?.map((facilitate) => (
              <Tag key={facilitate?.id}>
                {facilitate?.name?.replace(/\s/g, '\u00a0')}
              </Tag>
            ))
          : '-',
      ...filterPopup('facilitates'),
    },
    {
      title: 'TAGS',
      dataIndex: 'tags',
      key: 'tags',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.toy?.tags?.length > 0
          ? record?.toy?.tags?.map((tag) => (
              <Tag key={tag?.id}>{tag?.name?.replace(/\s/g, '\u00a0')}</Tag>
            ))
          : '-',
      ...filterPopup('tags'),
    },
    {
      title: 'CURRENTLY AVAILABLE',
      dataIndex: 'currentlyAvailable',
      key: 'currentlyAvailable',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.availableStock,
    },
    {
      title: 'RENTED OUT',
      dataIndex: 'rentedOut',
      key: 'rentedOut',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'DAMAGED STOCK',
      dataIndex: 'damagedStock',
      key: 'damagedStock',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.damagedStock,
    },
    {
      title: 'TOTAL STOCK',
      dataIndex: 'totalStock',
      key: 'totalStock',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.totalStock,
    },
    {
      title: 'ASSIGN SHELF',
      dataIndex: 'assignRack',
      key: 'assignRack',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => {
        const rack = record?.rack;
        return (
          <RackSelect
            size="small"
            className="library-select"
            showAction={['click']}
            onChange={(value, data) => {
              setRackData(data);
              setInventory(record);
              setIsAssignRackPrompts(true);
            }}
            value={rack?.id || null}
            rackData={{
              id: rack?.id,
              name: rack?.name,
            }}
            allowClear={false}
          />
        );
      },
    },
    {
      title: 'TRANSFERRED OUT',
      dataIndex: 'material',
      key: 'material',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.transferOut,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 100,
      render: (_, record) => (
        <>
          <div className="action-button">
            <div className="d-flex">
              <Tooltip
                title="Edit"
                placement="top"
                zIndex={4}
                className="table-action-button"
              >
                <Button
                  disabled={record?.toy?.isArchived}
                  type="link"
                  onClick={(e) => {
                    e?.stopPropagation();
                    setInventory(record);
                    setIsEditInventoryModalOpen(true);
                  }}
                >
                  <EditIcon
                    className={
                      record?.toy?.isArchived ? 'svg-icon-grey' : 'svg-icon'
                    }
                  />
                </Button>
              </Tooltip>
            </div>
          </div>
        </>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyInventoryList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isAssignRackPrompts}
        onOk={handleAssignRack}
        onCancel={() => setIsAssignRackPrompts(false)}
        okButtonProps={{ loading: isAssignRackLoading }}
      >
        Are you sure you want to assign
        <strong> {rackData?.children} </strong>?
      </Modal>
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <CommonPreview
        visible={previewVisible}
        setVisible={setPreviewVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <EditInventoryModal
        form={editInventoryForm}
        onFinish={handleEditInventoryToys}
        loadings={isEditInventoryLoading}
        isModalOpen={isEditInventoryModalOpen}
        setIsModalOpen={setIsEditInventoryModalOpen}
        inventory={inventory}
      />
      <BulkUpdateModal
        form={bulkUpdateForm}
        isModalOpen={isBulkUpdateModalOpen}
        setIsModalOpen={setIsBulkUpdateModalOpen}
        handleExport={handleExport}
        isExportLoading={isExportLoading}
        bulkUpdateFile={bulkUpdateFile}
        setBulkUpdateFile={setBulkUpdateFile}
        onFinish={handleBulkUpdate}
        loadings={isBulkUpdateLoading}
        isGoExportVisible={isGoExportVisible}
        setIsGoExportVisible={setIsGoExportVisible}
        isDesktop={isDesktop}
      />
      <div className={`${isDesktop ? 'd-flex justify-end mb-16' : ''}`}>
        <div className="d-flex justify-end">
          <div
            className={`${
              !isDesktop ? 'mb-16' : ''
            } overflow-scroll no-scroll-bar align-center`}
          >
            {!isView && (
              <Button
                type="primary"
                className="add-toy-btn ml-16"
                onClick={() => setIsBulkUpdateModalOpen(true)}
              >
                Bulk Update
              </Button>
            )}
            {!isView && (
              <Button
                type="primary"
                className={`add-toy-btn ml-16 ${isDesktop ? 'mr-16' : ''}`}
                onClick={() =>
                  history?.push(
                    `${ROUTES?.INVENTORY}${ROUTES?.BULK_UPDATE_LOG}/view`,
                  )
                }
              >
                View Bulk Update Log
              </Button>
            )}
          </div>
        </div>
        <div
          className={`d-flex align-center ${!isDesktop ? 'full-width' : ''}`}
        >
          <SearchComponent
            query={query}
            setQuery={setQuery}
            getData={handleSearch}
          />
          {!isDesktop && (
            <>
              <Badge dot={hasNonEmptyValueObj(finalWhereFilters)} size="small">
                <FilterIcon
                  className="mobile-svg-icon ml-16"
                  onClick={() => setIsAllFilterModalOpen(true)}
                />
              </Badge>
              <div className="ml-16 d-flex align-center">
                <SortDropdown
                  isOpen={isAllSortPopoverOpen}
                  setIsOpen={setIsAllSortPopoverOpen}
                  options={map(sort, (item) => item)}
                  sortedInfo={sortedInfo}
                  setSortedInfo={setSortedInfo}
                  onFinish={handleSort}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="d-flex justify-between mb-16 stock-details">
        <div>
          <div className="table-top-text">
            Total Stock{' '}
            {parseInt(
              totalToysDetails?.totalAvailableStock
                ? totalToysDetails?.totalAvailableStock
                : 0,
              10,
            ) +
              parseInt(
                totalToysDetails?.totalRentedOut
                  ? totalToysDetails?.totalRentedOut
                  : 0,
                10,
              )}
          </div>
        </div>
        <div>
          <div className="table-top-text">
            Toys Rented Out {totalToysDetails?.totalRentedOut || 0}
          </div>
        </div>
        <div>
          <div className="table-top-text">
            Toys available for rent {totalToysDetails?.totalAvailableStock || 0}
          </div>
        </div>
      </div>
      {isDesktop ? (
        <>
          <CommonTable
            locale={locale}
            columns={columns}
            data={inventoryList || []}
            loading={isInventoryLoading}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(record) => record?.id}
            rowClassName={(record) =>
              `pointer table-cell ${
                record?.toy?.isArchived ? 'archived-row-color' : ''
              }`
            }
            onRow={(record) => ({
              onClick: () => {
                history?.push(
                  `${ROUTES?.INVENTORY}${ROUTES?.TOY}/view/${record?.toy?.id}`,
                );
              },
            })}
          />
        </>
      ) : (
        <div className="mobile-container-nav-action no-scroll-bar">
          <AllFilterModal
            onFinish={handleFilter}
            isModalOpen={isAllFilterModalOpen}
            setIsModalOpen={setIsAllFilterModalOpen}
            finalWhereFilters={finalWhereFilters}
            setFinalWhereFilters={setFinalWhereFilters}
          />
          <InventoryList
            inventoryList={inventoryList}
            setVisible={setVisible}
            setToyImages={setToyImages}
            infiniteScrollRef={infiniteScrollRef}
            setInventory={setInventory}
            setIsEditInventoryModalOpen={setIsEditInventoryModalOpen}
            isFetchMoreToysLoading={isFetchMoreToysLoading}
            isEmptyInventoryList={isEmptyInventoryList}
            isInventoryLoading={isInventoryLoading}
          />
        </div>
      )}
    </>
  );
};

export default InventoryTable;
