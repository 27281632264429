import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CATEGORIES_ADMIN = gql`
  query CategoriesAdmin($filter: CategoryFilter, $sort: CategorySort) {
    categoriesAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
        isArchived
        createdAt
        image
        imageUuid
      }
    }
  }
`;

export const GET_CATEGORY_IMAGE_SIGNED_PUT_URL = gql`
  query GetCategoryImageSignedPutUrl(
    $data: GetCategoryImageSignedPutUrlInput!
  ) {
    getCategoryImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
