import { gql } from '@apollo/client';

export const GET_PAYMENT_LINK = gql`
  query personalizedOffer($where: OfferUniqueInput!) {
    personalizedOffer(where: $where) {
      data {
        id
        plan {
          id
        }
        uniqueToken
        link
        actualPrice
        discountPrice
        offerPrice
        expiryDate
        paymentStatus
        status
        isRedeemed
        createdAt
        updatedAt
        notes
        __typename
      }
      message
      __typename
    }
  }
`;

// Query to fetch personalized offers
export const GET_PAYMENT_LINKS = gql`
  query GetAllPersonalizedOffers {
    personalizedOffers {
      data {
        id
        plan {
          id
          name
          description
          price
          subscriptionFeatures {
          planVersion
          }
          type
        }
        uniqueToken
        actualPrice
        discountPrice
        offerPrice
        expiryDate
        paymentStatus
        isRedeemed
        link
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

// Query to fetch a single personalized offer
export const GET_PERSONALIZED_OFFER = gql`
  query personalizedOffer($where: OfferUniqueInput!) {
    personalizedOffer(where: $where) {
      data {
        id
        plan {
          id
        }
        uniqueToken
        actualPrice
        discountPrice
        offerPrice
        expiryDate
        paymentStatus
        isRedeemed
        createdAt
        updatedAt
      }
      message
    }
  }
`;

export const CREATE_PERSONALIZED_OFFER = gql`
  mutation createPersonalizedOffer($data: CreatePersonalizedOfferInput!) {
    createPersonalizedOffer(data: $data) {
      data {
        id
        uniqueToken
      }
      message
    }
  }
`;
