import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Tag, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  PAYMENT_LINK_STATUS,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  PLAN_TYPES,
  ROUTES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import { GET_PAYMENT_LINKS } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  search: '',
  status: '',
  expiredAtDate: {
    from: null,
    to: null,
  },
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const PaymentLinksTable = ({ exportFilter, setExportFilter }) => {
  const history = useHistory();
  const {
    state: { permissions },
  } = useContext(AppContext);

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [filter, setFilter] = useState(initialFilter);

  const [paymentLinks, { loading, data }] = useLazyQuery(GET_PAYMENT_LINKS, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: (error) => {},
  });

  useEffect(() => {
    paymentLinks({
      variables: {
        filter: {
          ...filter,
          limit: paginationProp.pageSize,
          skip: (paginationProp.current - 1) * paginationProp.pageSize,
        },
        sort: sortedInfo.column
          ? {
              field: sortedInfo.field,
              order: sortedInfo.order === 'ascend' ? 'ASC' : 'DESC',
            }
          : initialSort,
      },
    });
  }, [filter, paginationProp, sortedInfo]);

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    message.success('Link copied to clipboard');
  };

  const mapApiResponseToTableData = (apiData) => {
    if (!apiData) return [];
    return apiData.map((offer) => ({
      id: offer.id,
      title: `${offer?.plan?.name}-${PLAN_TYPES?.[offer?.plan?.type]?.key} (${offer?.plan?.subscriptionFeatures?.planVersion})`,
      offerPrice: offer?.offerPrice,
      discountPrice: offer?.discountPrice,
      actualPrice: offer?.actualPrice,
      status: offer?.paymentStatus,
      createdBy: offer?.createdBy,
      createdAt: offer?.createdAt,
      expiryDate: offer?.expiryDate,
      paymentLink: offer?.link,
    }));
  };

  const tableData = mapApiResponseToTableData(
    data?.personalizedOffers?.data || [],
  );

  const columns = [
    {
      title: 'Plan Name',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
      fixed: 'left',
    },
    {
      title: 'Offer Price (₹)',
      dataIndex: 'offerPrice',
      key: 'offerPrice',
      width: '10%',
      fixed: 'left',
      render: (offerPrice) => `₹${offerPrice}`,
    },
    {
      title: 'Discount Price (₹)',
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      width: '10%',
      render: (discountPrice) => `₹${discountPrice}`,
    },
    {
      title: 'Actual Price (₹)',
      dataIndex: 'actualPrice',
      key: 'actualPrice',
      width: '10%',
      render: (actualPrice) => `₹${actualPrice}`,
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status) => (
        <Tag color={PAYMENT_LINK_STATUS[status]?.color}>
          {PAYMENT_LINK_STATUS[status]?.label}
        </Tag>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '15%',
      render: (createdBy) => `${createdBy.firstName} ${createdBy.lastName}`,
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Expires On',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      width: '15%',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '15%',
      render: (_, record) => (
        <div className="action-button">
          {hasPermission(
            PERMISSIONS_KEY.OFFERS_COUPONS,
            PERMISSION_TYPE.UPDATE,
            permissions,
          ) && (
            <Tooltip
              title="Edit"
              placement="top"
              zIndex={4}
              className="table-action-button"
            >
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={(e) => {
                  e?.stopPropagation();
                  history.push(
                    `${ROUTES?.OFFERS}${ROUTES.PAYMENT_LINKS}/edit/${record.id}`,
                  );
                }}
              />
            </Tooltip>
          )}
          <Tooltip title="Copy Offer Link">
            <Button
              type="text"
              icon={<CopyOutlined />}
              onClick={(e) => {
                e?.stopPropagation();
                handleCopyLink(record.paymentLink);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    setFilter({ ...filter, ...filters });
  };

  return (
    <CommonTable
      columns={columns}
      data={tableData}
      rowClassName="pointer table-cell"
      loading={loading}
      onChange={handleTableChange}
      paginationConfig={{
        total: data?.personalizedOffers?.count || 0,
        pageSize: paginationProp.pageSize,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`,
      }}
      rowKey="id"
      onRow={(record) => ({
        onClick: () => {
          history.push(
            `${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}/view/${record.id}`,
          );
        },
      })}
    />
  );
};

export default PaymentLinksTable;
