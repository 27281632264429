import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const TRANSFER_ORDER_ADMIN = gql`
  query TransferOrdersAdmin(
    $filter: TransferOrderFilter
    $sort: TransferOrderSort
  ) {
    transferOrdersAdmin(filter: $filter, sort: $sort) {
      data {
        type
        transferOrderNumber
        createdAt
        statusHistory {
          createdAt
          newStatus
          oldStatus
        }
        actionUser {
          userNumber
          firstName
          id
          lastName
        }
        creator {
          userNumber
          firstName
          id
          lastName
        }
        id
        metaData {
          damageNote
          requestedOn
          images {
            key
            url
          }
          videoUrl
        }
        orderItem {
          id
          status
          toy {
            name
            toyNumber
            id
          }
          order {
            id
            status
            orderNumber
          }
        }
        senderLibrary {
          id
          libraryNumber
          name
        }
        receiverLibrary {
          id
          libraryNumber
          name
        }
        requestGenerator {
          userNumber
          firstName
          id
          lastName
        }
        status
        returnOrderItem {
          id
          status
          returnOrder {
            id
            status
            returnOrderNumber
          }
        }
        updatedAt
        user {
          userNumber
          firstName
          id
          lastName
        }
      }
      count
    }
  }
`;
