import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const TOYS = gql`
  query Toys($filter: ToysFilter, $sort: ToysSort) {
    toys(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        isArchived
        toyNumber
        dimensions
        price
        carbonEmission
        type
        slug
        ageGroups {
          id
          maxAge
          minAge
        }
        brand {
          id
          name
        }
        categories {
          id
          name
        }
        tags {
          id
          name
        }
        genres {
          id
          name
        }
        facilitates {
          id
          name
        }
        description
        images {
          url
          size
          order
          key
          contentType
        }
        material {
          id
          name
        }
        bag {
          id
          name
          size
          image
        }
        name
        id
      }
    }
  }
`;

export const TOY_ADMIN = gql`
  query toyAdmin($where: ToyUniqueInput!) {
    toyAdmin(where: $where) {
      name
      imageUuid
      createdAt
      isArchived
      url
      price
      carbonEmission
      toyNumber
      dimensions
      slug
      material {
        name
        id
      }
      images {
        url
        size
        order
        key
        contentType
      }
      id
      description
      shortDescription
      eanNumber
      type
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      facilitates {
        id
        name
      }
      ageGroups {
        maxAge
        minAge
        id
      }
      genres {
        id
        name
      }
      brand {
        name
        id
      }
      bag {
        id
        name
        size
        image
      }
      videoUrl
      includedContents {
        id
        quantity
        value
        key
      }
      excludedContents {
        id
        quantity
        key
        value
      }
    }
  }
`;

export const CATEGORIES = gql`
  query Categories($filter: CategoryFilter, $sort: CategorySort) {
    categories(filter: $filter, sort: $sort) {
      data {
        name
        id
        createdAt
        order
        updatedAt
      }
    }
  }
`;

export const CONTENT_MASTERS_ADMIN = gql`
  query ContentMastersAdmin(
    $filter: ContentMasterFilter
    $sort: ContentMasterSort
  ) {
    contentMastersAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        key
        value
      }
    }
  }
`;

export const FACILITATES_ADMIN = gql`
  query FacilitatesAdmin($filter: FacilitateFilter, $sort: FacilitateSort) {
    facilitatesAdmin(filter: $filter, sort: $sort) {
      data {
        name
        id
      }
    }
  }
`;

export const GENRES_ADMIN = gql`
  query GenresAdmin($filter: GenreFilter, $sort: GenreSort) {
    genresAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;
export const TAGS_ADMIN = gql`
  query TagsAdmin($filter: TagFilter, $sort: TagSort) {
    tagsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const AGE_GROUPS = gql`
  query AgeGroups($filter: AgeGroupFilter, $sort: AgeGroupSort) {
    ageGroups(filter: $filter, sort: $sort) {
      data {
        createdAt
        id
        maxAge
        minAge
        order
        updatedAt
      }
    }
  }
`;

export const BRANDS_ADMIN = gql`
  query CatalogBrandsAdmin($filter: BrandFilter, $sort: BrandSort) {
    catalogBrandsAdmin(filter: $filter, sort: $sort) {
      data {
        id
        name
        createdAt
        order
        updatedAt
      }
    }
  }
`;

export const MATERIALS_ADMIN = gql`
  query MaterialsAdmin($filter: MaterialFilter, $sort: MaterialSort) {
    materialsAdmin(filter: $filter, sort: $sort) {
      data {
        id
        name
        order
        createdAt
        updatedAt
      }
    }
  }
`;

export const BAGS_ADMIN = gql`
  query BagsAdmin($filter: BagFilter, $sort: BagSort) {
    bagsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        id
        image
        isArchived
        name
        size
        type
      }
    }
  }
`;

export const GET_TOYS_SIGNED_URL = gql`
  query GetToyImagesSignedPutUrl($data: GetToyImagesSignedPutUrlInput!) {
    getToyImagesSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const GET_TOY_VIDEO_SIGNED_PUT_URL = gql`
  query GetToyVideoSignedPutUrl($data: GetToyVideoSignedPutUrlInput!) {
    getToyVideoSignedPutUrl(data: $data) {
      signedUrl
      fileName
    }
  }
`;

export const GET_SIGNED_TOYS_UPLOAD_URL = gql`
  query GetToyUploadSignedPutUrl($data: GetToyUploadSignedPutUrlInput) {
    getToyUploadSignedPutUrl(data: $data) {
      signedUrl
      fileName
    }
  }
`;
