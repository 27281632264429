import { PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Input, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  formValidatorRules,
  getBase64,
  validateImagesGenres,
} from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { required, whitespaceNotAllowed } = formValidatorRules;

const CategoryModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  category,
  setCategory,
  setCollectionPreviewImage,
  setPreviewVisible,
  setCategoryImages,
  categotyImages,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setCategory();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue({ name: category?.name });
  }, [category]);
  const handleImageChange = (info) => {
    validateImagesGenres(info, form, setCategoryImages, 'image');
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setPreviewVisible(true);
    setCollectionPreviewImage(fileObj?.url || preview);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={category ? 'Edit Category' : 'Add New Category'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            name="name"
            label="Category Name"
            rules={[
              { ...required, message: 'Please Enter Category Name!' },
              whitespaceNotAllowed,
            ]}
          >
            <Input
              allowClear
              placeholder="Enter Category Name"
              maxLength={100}
            />
          </Item>

          <Item
            label="Image"
            name="image"
            rules={
              categotyImages?.length !== 1
                ? [{ required: true, message: 'Image Upload is Required!' }]
                : []
            }
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={categotyImages}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={handleImageChange}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
            >
              {categotyImages?.length !== 1 && <PlusOutlined />}
            </Upload>
          </Item>
          <Col xs={24} className="notes">
            <div>Image Notes </div>
            <ul>
              <li>Image upload supported formats are JPEG, JPG, PNG</li>
              <li>The minimum dimensions is 150 * 150 pixels</li>
              <li>Allow maximum size of 200 KB</li>
            </ul>
          </Col>
        </Form>
      </ModalComponent>
    </>
  );
};

export default CategoryModal;
