import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../common/constants';
import CreatePaymentLink from './pages/CreatePaymentLink';
import EditPaymentLink from './pages/EditPaymentLink';
import PaymentLinks from './pages/PaymentLinks';
import ViewPaymentLink from './pages/ViewPaymentLink';

const PaymentLinksWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.OFFERS_COUPONS}>
          <PaymentLinks {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}/add`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.CREATE}
          permissionKey={PERMISSIONS_KEY?.OFFERS_COUPONS}
        >
          <CreatePaymentLink {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}/edit/:id`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.OFFERS_COUPONS}
        >
          <EditPaymentLink {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}/view/:id`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.OFFERS_COUPONS}
        >
          <ViewPaymentLink {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default PaymentLinksWrapper;
