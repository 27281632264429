import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ORDERS = gql`
  query Orders($filter: OrderFilter, $sort: OrderSort) {
    orders(filter: $filter, sort: $sort) {
      count
      data {
        orderNumber
        id
        deliveryType
        orderItemCount
        deliveryPartner {
          firstName
          lastName
          id
          userNumber
        }
        user {
          firstName
          lastName
          id
          userNumber
          pincode {
            pincode
          }
        }
        createdAt
        expectedDeliveryTime
        status
        cancellationData {
          additionalComment
          reason
        }
      }
    }
  }
`;

export const ORDER_ITEMS = gql`
  query OrderItems($where: OrderUniqueInput) {
    orderItems(where: $where) {
      count
      data {
        id
        transferOrders {
          status
        }
        toy {
          id
          toyNumber
          name
          category {
            name
          }
          ageGroup {
            minAge
            maxAge
          }
        }
        status
      }
    }
  }
`;

export const PINCODES = gql`
  query Pincodes($filter: PincodesFilter, $sort: PincodesSort) {
    pincodes(filter: $filter, sort: $sort) {
      count
      data {
        pincode
        id
      }
    }
  }
`;
