import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Card, Col, Row, Statistic, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  GUTTER_VARIATIONS,
  MODULES,
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import StatisticsCards from '../components/StatisticsCards';
import { DASHBOARD_LIBRARY } from '../graphql/Queries';

const LibrarianDashboard = () => {
  const [statistics, setStatistics] = useState({});
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(true);
  const {
    state: { isDesktop },
  } = useContext(AppContext);

  const [dashboardLibrary] = useLazyQuery(DASHBOARD_LIBRARY, {
    onCompleted: (response) => {
      setStatistics(response?.dashboardLibrary);
      setIsStatisticsLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    dashboardLibrary();
  }, []);

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (_, record) => (record?.name ? record?.name : '-'),
    },
    {
      title: 'TOTAL ORDER QTY',
      dataIndex: 'totalOrderedQty',
      key: 'totalOrderedQty',
      width: '20%',
      render: (_, record) =>
        record?.totalOrderedQty ? record?.totalOrderedQty : '-',
    },
    {
      title: 'LAST ORDER QTY',
      dataIndex: 'lastOrderQty',
      key: 'lastOrderQty',
      width: '20%',
      render: (_, record) =>
        record?.lastOrderQty ? record?.lastOrderQty : '-',
    },

    {
      title: 'LAST ORDER DATE',
      dataIndex: 'lastOrderDate',
      key: 'lastOrderDate',
      render: (_, record) =>
        record?.lastOrderDate
          ? formatDate(record?.lastOrderDate, DATE_FORMATS?.REGULAR)
          : '-',
    },
  ];

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.DASHBOARD}</div>
      </Portal>
      <Card className="site-statistic-demo-card-library">
        {isStatisticsLoading ? (
          <LoaderComponent />
        ) : (
          <>
            <StatisticsCards statistics={statistics} />

            <h3 className="mt-24">Packaging Material Ordered</h3>
            {!isDesktop ? (
              <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
                {statistics?.bagOrders?.map((bagOrder) => (
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                    key={bagOrder?.id}
                  >
                    <Card>
                      <div className="info-card-container">
                        <Statistic
                          title={bagOrder?.name}
                          value={bagOrder?.totalOrderedQty}
                          className="dashboard-statistic"
                        />
                        <div className="info-icon-container">
                          <Tooltip
                            title={`Total ${bagOrder?.totalOrderedQty} Quantity has been ordered till date`}
                            trigger="click"
                            className="pointer"
                            placement={
                              // eslint-disable-next-line no-undef
                              window.innerWidth > BREAKPOINTS.tablet
                                ? 'top'
                                : 'left'
                            }
                          >
                            <InfoCircleOutlined className="info-icon" />
                          </Tooltip>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <CommonTable
                columns={columns}
                data={statistics?.bagOrders || []}
                loading={isStatisticsLoading}
                rowKey={(record) => record?.id}
              />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default LibrarianDashboard;
