/* eslint-disable no-console */
import { Badge, Button, Card, Checkbox, Dropdown, Image } from 'antd';
import React from 'react';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as MoreIcon } from '../../../../../assets/svg/ellipsis-vertical-solid.svg';
import {
  CANCELLATION_TYPE,
  DATE_FORMATS,
  DELIVERY_TYPE,
  FULFILLMENT_STATUS,
  LIBRARY_ALL_DELIVERY_TABLE_ACTIONS_OPTIONS,
  NEW_ORDER_TABLE_ACTIONS_OPTIONS,
  ORDER_STATUS,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';

const AllOrderCard = ({
  order,
  infiniteScrollRef,
  handleOrderChange,
  setOrder,
  setIsAllOrderItemModalOpen,
  isNewPath,
  setIsNotePrompts,
  setIsOrderAction,
  handleChallanDownload,
  isChallanLoading,
  handleChallanReset,
  isResetLoading,
  setVisible,
  setToyImages,
  handleSelectOrders,
  selectedOrders,
}) => {
  const handleMenuClick = (e) => {
    handleOrderChange(e?.key, order);
  };

  let selectedValue = null;
  if (
    order?.status === ORDER_STATUS?.CANCELLED?.value ||
    order?.status === ORDER_STATUS?.CONFIRMED?.value
  ) {
    if (order?.status === ORDER_STATUS?.CANCELLED?.value) {
      selectedValue = FULFILLMENT_STATUS?.UNFULFILLED?.value;
    } else {
      selectedValue = FULFILLMENT_STATUS?.FULFILLED?.value;
    }
  } else {
    selectedValue = null;
  }

  const menuProps = isNewPath
    ? {
        items: NEW_ORDER_TABLE_ACTIONS_OPTIONS?.map((item) => ({
          label: item?.label,
          key: item?.value,
        })),
        onClick: handleMenuClick,
        disabled: !!selectedValue,
      }
    : {
        items: LIBRARY_ALL_DELIVERY_TABLE_ACTIONS_OPTIONS?.map((item) => ({
          label: item?.label,
          key: item?.value,
        })),
        onClick: handleMenuClick,
        disabled:
          order?.status === ORDER_STATUS?.CANCELLED?.value ||
          order?.status === ORDER_STATUS?.DELIVERED?.value ||
          order?.status === ORDER_STATUS?.SHIPPED?.value ||
          order?.status === ORDER_STATUS?.NOT_DELIVERED?.value,
      };

  const shippedAttachments =
    order?.deliveryAttachments?.filter(
      (attachment) =>
        attachment?.deliveryAttachmentsType === ORDER_STATUS?.SHIPPED?.value,
    ) || [];

  const deliveredAttachments =
    order?.deliveryAttachments?.filter((attachment) =>
      [ORDER_STATUS?.DELIVERED?.value, null]?.includes(
        attachment?.deliveryAttachmentsType,
      ),
    ) || [];

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 mobile-card"
      title={
        <div className="overflow-scroll no-scroll-bar">
          {!isNewPath && (
            <Checkbox
              className="mr-12"
              onClick={(e) => e.stopPropagation()}
              disabled={
                ![
                  ORDER_STATUS?.READY_TO_SHIP?.value,
                  ORDER_STATUS?.SHIPPED?.value,
                  ORDER_STATUS?.CONFIRMED?.value,
                  ORDER_STATUS?.DELIVERED?.value,
                  ORDER_STATUS?.NOT_DELIVERED?.value,
                ]?.includes(order?.status)
              }
              checked={selectedOrders?.includes(order?.id)}
              onChange={(e) => {
                e.stopPropagation();
                handleSelectOrders(order?.id);
              }}
            />
          )}

          {order?.orderNumber}
        </div>
      }
      extra={
        <div className="d-flex align-center">
          <Badge
            color={ORDER_STATUS?.[order?.status]?.color}
            text={ORDER_STATUS?.[order?.status]?.text}
            className="mr-16 ml-16"
          />
          <span>
            {order?.cancellationData?.type ===
              CANCELLATION_TYPE?.LIBRARY?.value && (
              <span className="action-button">
                <CommentIcon
                  className="mr-16 pointer svg-icon"
                  onClick={(e) => {
                    e?.stopPropagation();
                    setIsNotePrompts(true);
                    setOrder(order);
                    setIsOrderAction(true);
                  }}
                />
              </span>
            )}
          </span>
          <div onClick={(e) => e?.stopPropagation()} className="d-flex">
            <Dropdown
              getPopupContainer={(trigger) => trigger?.parentNode}
              menu={menuProps}
              disabled={
                order.transferOrderItemCount !== 0 &&
                order.transferOrderItemCount !== null
              }
            >
              <MoreIcon
                className="pointer mobile-svg-icon"
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              />
            </Dropdown>
          </div>
        </div>
      }
      ref={infiniteScrollRef}
      onClick={() => {
        setOrder(order);
        setIsAllOrderItemModalOpen(true);
      }}
    >
      <div>
        <div className="mb-8">
          <span className="mobile-card-label">Quantity: </span>
          <span>{order?.orderItemCount || 'No Quantity'}</span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Delivery Type: </span>
          <span>
            {DELIVERY_TYPE?.[order?.deliveryType]?.label || 'No Delivery Type'}
          </span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">User ID: </span>
          <span>{order?.user?.userNumber || 'No User ID'}</span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Delivery Partner Name: </span>
          <span>
            {order?.deliveryPartner?.firstName ||
            order?.deliveryPartner?.lastName ? (
              <span className="table-data-with-id">{`${
                order?.deliveryPartner?.firstName
                  ? order?.deliveryPartner?.firstName
                  : ''
              } ${
                order?.deliveryPartner?.lastName
                  ? order?.deliveryPartner?.lastName
                  : ''
              }`}</span>
            ) : (
              'No Delivery Partner'
            )}
            {order?.deliveryPartner?.userNumber ? (
              <span className="font-size-12">
                ({order?.deliveryPartner?.userNumber})
              </span>
            ) : (
              ''
            )}
          </span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Pincode: </span>
          <span>{order?.pincode?.pincode || 'No Pincode'}</span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Placed On: </span>
          <span>{formatDate(order?.createdAt, DATE_FORMATS?.WITH_TIME)}</span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">
            {isNewPath ? 'Estimated Shipping Date:' : 'Delivered On:'}{' '}
          </span>
          <span>
            {formatDate(order?.deliveredOn, DATE_FORMATS?.WITH_TIME) || '-'}
          </span>
        </div>
        {!isNewPath && (
          <>
            <div className="mb-8">
              <span className="mobile-card-label">Shipped Attachments: </span>
              <span>
                {shippedAttachments?.length > 0 ? (
                  <Image
                    preview={{
                      visible: false,
                    }}
                    width={20}
                    height={20}
                    src={shippedAttachments?.[0]?.url}
                    onClick={(e) => {
                      e?.stopPropagation();
                      setVisible(true);
                      setToyImages(shippedAttachments);
                    }}
                    className="pointer"
                  />
                ) : (
                  <span>No Images</span>
                )}
                {shippedAttachments?.length > 1
                  ? ` + ${shippedAttachments?.length - 1}`
                  : ''}
              </span>
            </div>
            <div className="mb-8">
              <span className="mobile-card-label">Delivered Attachments: </span>
              <span>
                {deliveredAttachments?.length > 0 ? (
                  <Image
                    preview={{
                      visible: false,
                    }}
                    width={20}
                    height={20}
                    src={deliveredAttachments?.[0]?.url}
                    onClick={(e) => {
                      e?.stopPropagation();
                      setVisible(true);
                      setToyImages(deliveredAttachments);
                    }}
                    className="pointer"
                  />
                ) : (
                  <span>No Images</span>
                )}
                {deliveredAttachments?.length > 1
                  ? ` + ${deliveredAttachments?.length - 1}`
                  : ''}
              </span>
            </div>
          </>
        )}
        {!isNewPath && (
          <>
            <div className="dashed-line" />
            <div className="d-flex justify-between flex-wrap">
              <Button
                type="primary"
                className="mr-16"
                loading={isChallanLoading?.[order?.id]}
                onClick={(e) => {
                  e?.stopPropagation();
                  // eslint-disable-next-line no-undef
                  const windowReference = window.open();
                  handleChallanDownload(order?.id, windowReference);
                }}
                disabled={
                  ![
                    ORDER_STATUS?.READY_TO_SHIP?.value,
                    ORDER_STATUS?.SHIPPED?.value,
                    ORDER_STATUS?.CONFIRMED?.value,
                    ORDER_STATUS?.DELIVERED?.value,
                    ORDER_STATUS?.NOT_DELIVERED?.value,
                  ]?.includes(order?.status)
                }
              >
                Download Challan
              </Button>
              <Button
                loading={isResetLoading?.[order?.id]}
                onClick={(e) => {
                  e?.stopPropagation();
                  handleChallanReset(order?.id);
                }}
                disabled={
                  ![
                    ORDER_STATUS?.READY_TO_SHIP?.value,
                    ORDER_STATUS?.CONFIRMED?.value,
                  ]?.includes(order?.status)
                }
              >
                Reset Challan
              </Button>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default AllOrderCard;
