import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Modal,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as FilterIcon } from '../../../../../assets/svg/sliders-solid.svg';
import {
  DATE_FORMATS,
  DELIVERY_TYPE,
  FULFILLMENT_STATUS,
  LIBRARY_FACILITY_TYPE,
  LIMIT,
  NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS,
  ORDER_STATUS,
  ORDER_TYPE_OPTIONS,
  ORDER_TYPES,
  REQUEST_TYPES,
  RETURN_ORDER_ITEM_STATUS,
  RETURN_ORDER_TABLE_ACTIONS_OPTIONS,
  ROUTES,
  SENT_ORDER_LABEL,
  SORT,
  TRANSFER_ORDER_STATUS,
  TRANSFER_ORDER_TYPE,
} from '../../../../../common/constants';
import {
  fileUpload,
  formatDate,
  hasNonEmptyValueObj,
  openNotification,
} from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import SelectComponent from '../../../../../components/SelectComponent';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import NoteModal from '../../../../../modules/orders/modules/transfer/components/NoteModal';
import AddCommentModal from '../../all/components/AddCommentModal';
import ReportDamageModal from '../../all/components/ReportDamageModal';
import { CREATE_REQUEST_LIBRARY } from '../../all/graphql/Mutations';
import {
  GET_LIBRARY_REQUEST_TOY_VIDEO_SIGNED_PUT_URL,
  UPDATE_TRANSFER_ORDERS,
} from '../graphql/Mutations';
import {
  GET_LIBRARY_DAMAGE_REQUEST_IMAGE_SINGED_PUT_URL,
  TRANSFER_ORDER,
} from '../graphql/Queries';
import TransferFilterOrderModal from './TransferFilterOrderModal';
import TransferList from './TransferList';
import TransferNestedDataModal from './TransferNestedDataModal';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialOrderFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialOrderSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const TransferOrderTable = ({
  exportFilter,
  setExportFilter,
  orderTransferType,
}) => {
  const {
    state: {
      commonPermissions: {
        isUserPermission,
        isToyPermission,
        isLibraryPermission,
      },
      currentUser,
      isDesktop,
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [reportDamageForm] = Form.useForm();
  const [orderList, setOrderList] = useState([]);
  const [orderSearchTerm, setOrderSearchTerm] = useState('');
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [order, setOrder] = useState({});
  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isRequestPrompts, setIsRequestPrompts] = useState(false);
  const [isRequestLoadings, setIsRequestLoadings] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isRequestsPrompts, setIsRequestsPrompts] = useState(false);
  const [isNotePrompts, setIsNotePrompts] = useState(false);
  const [isReportDamageModalOpen, setIsReportDamageModalOpen] = useState(false);
  const [isReportDamageLoading, setIsReportDamageLoading] = useState(false);
  const [isConfirmReturnPrompts, setIsConfirmReturnPrompts] = useState(false);
  const [damageToyImage, setDamageToyImage] = useState([]);
  const [toyVideo, setToyVideo] = useState([]);
  const [toyPreviewImage, setToyPreviewImage] = useState();
  const [toyPreviewVideo, setToyPreviewVideo] = useState();
  const [isFulfillPrompts, setIsFulfillPrompts] = useState(false);
  const [isAddCommentModalOpen, setIsAddCommentModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [
    isTransferOrderItemModalOpen,
    setIsTransferOrderItemModalOpen,
  ] = useState(false);
  const [isAllFilterModalOpen, setIsAllFilterModalOpen] = useState(false);
  const [isFetchMoreOrderLoading, setIsFetchMoreOrderLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const transferTypeIn = orderTransferType === TRANSFER_ORDER_TYPE?.IN?.key;
  const [transferOrders] = useLazyQuery(TRANSFER_ORDER, {
    onCompleted: (response) => {
      if (isDesktop) {
        setOrderList([...response?.transferOrders?.data]);
      } else {
        setOrderList([...orderList, ...response?.transferOrders?.data]);
      }
      if (
        response?.transferOrders?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOrderList(true);
      } else {
        setIsEmptyOrderList(false);
      }

      if (response?.transferOrders?.data?.length < LIMIT) {
        setIsActive(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.transferOrders?.count,
      };
      setPaginationProp(pagination);
      setIsOrderLoading(false);
      setIsFetchMoreOrderLoading(false);
    },
    fetchPolicy: 'no-cache',
    onError() {},
  });

  const [createRequestLibrary] = useMutation(CREATE_REQUEST_LIBRARY, {
    onError: () => {},
  });

  const [updateTransferOrders] = useMutation(UPDATE_TRANSFER_ORDERS, {
    onError: () => {},
  });

  const [getLibraryRequestToyVideoSignedPutUrl] = useLazyQuery(
    GET_LIBRARY_REQUEST_TOY_VIDEO_SIGNED_PUT_URL,
    {
      fetchPolicy: 'network-only',
      onError() {
        setIsReportDamageLoading(false);
      },
    },
  );

  const [getSignUrl] = useLazyQuery(
    GET_LIBRARY_DAMAGE_REQUEST_IMAGE_SINGED_PUT_URL,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    transferOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          type: orderTransferType,
        },
        sort: initialOrderSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsOrderLoading(true);
    setSortedInfo(sorter);
    transferOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          skip,
          limit: pagination?.pageSize,
          type: orderTransferType,
          search: orderSearchTerm,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          orderPhase:
            finalWhereFilters?.type?.length > 0
              ? finalWhereFilters?.type
              : undefined,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOrderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setOrderList([]);
    transferOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          type: orderTransferType,
          search: trimValue,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          orderPhase:
            finalWhereFilters?.type?.length > 0
              ? finalWhereFilters?.type
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleRequest = async (values, status, formValues = null) => {
    setIsRequestLoadings(true);
    const response = await updateTransferOrders({
      variables: {
        where: {
          transferOrderIds: values,
        },
        data: {
          orderPhase:
            order?.type === ORDER_TYPES.ORDER
              ? ORDER_TYPES.ORDER
              : ORDER_TYPES.RETURN,
          status,
          reason: formValues?.reason,
          additionalComment: formValues?.comment,
        },
      },
    });
    if (response?.data) {
      if (!isDesktop) {
        setOrderList([]);
      }
      setIsOrderLoading(true);
      setIsRequestPrompts(false);
      setIsRequestsPrompts(false);
      setIsConfirmReturnPrompts(false);
      setIsFulfillPrompts(false);
      setIsAddCommentModalOpen(false);
      setSelectedRowKeys([]);
      transferOrders({
        variables: {
          filter: {
            ...initialOrderFilter,
            limit: paginationProp?.pageSize || LIMIT,
            type: orderTransferType,
            search: orderSearchTerm,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            orderPhase:
              finalWhereFilters?.type?.length > 0
                ? finalWhereFilters?.type
                : undefined,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOrderSort,
        },
      });
    }
    setIsRequestLoadings(false);
  };

  const handleReportDamage = async (values) => {
    setIsReportDamageLoading(true);
    const uuid = uuidv4();
    let damageToyFileName = [];
    let video = '';
    if (values?.video?.length > 0) {
      const { name } = values?.video?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `toy/${uuid}/${newFilename}`;

      const res = await getLibraryRequestToyVideoSignedPutUrl({
        variables: {
          data: {
            fileName: fileKey,
            type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          },
        },
      });
      if (res?.data) {
        try {
          try {
            await fileUpload(
              res?.data?.getLibraryRequestToyVideoSignedPutUrl?.signedUrl,
              toyVideo?.[0]?.originFileObj,
            );
            video = res?.data?.getLibraryRequestToyVideoSignedPutUrl?.fileName;
          } catch (error) {
            throw new Error(
              `${toyVideo?.name} upload failed. Please try again.`,
            );
          }
        } catch (error) {
          setIsReportDamageLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    if (!values?.damageToyImage?.url && values?.damageToyImage?.length > 0) {
      const toyImageArray = values?.damageToyImage?.map((item) => {
        const { name } = item;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `toy/${uuid}/${newFilename}`;

        return fileKey;
      });

      const res = await getSignUrl({
        variables: {
          data: {
            fileNames: toyImageArray,
            type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          },
        },
      });

      if (res?.data) {
        try {
          const response = await Promise.all(
            res?.data?.getLibraryDamageRequestImageSignedPutUrl?.map(
              async (signedData, index) => {
                try {
                  await fileUpload(
                    signedData?.signedUrl,
                    values?.damageToyImage?.[index]?.originFileObj,
                  );
                  return {
                    key: signedData?.fileName,
                  };
                } catch (error) {
                  throw new Error(
                    `${values?.damageToyImage?.[index]?.name} upload failed. Please try again.`,
                  );
                }
              },
            ),
          );

          damageToyFileName = response;
        } catch (error) {
          setIsReportDamageLoading(false);
          openNotification('error', error.message);
        }
      }
      setDamageToyImage([]);
    }

    const libraryDamageRequest =
      order?.type === ORDER_TYPES.ORDER
        ? {
            transferOrderId: order?.id,
            orderPhase: ORDER_TYPES.ORDER,
          }
        : {
            returnOrderItemId: order?.returnOrderItem?.id,
            includedContents: values?.includedContentMasters,
          };

    try {
      const response = await createRequestLibrary({
        variables: {
          data: {
            images: damageToyFileName || undefined,
            videoKey: video,
            libraryDamageRequest,
            description: values?.description,
            type: REQUEST_TYPES?.LIBRARY_DAMAGE,
          },
        },
      });
      if (response?.data) {
        setIsReportDamageModalOpen(false);
        reportDamageForm?.resetFields();
        setToyVideo([]);
        setDamageToyImage([]);
        setToyPreviewVideo();
        setToyPreviewVideo();
        setIsOrderLoading(true);
        setOrderList([]);
        transferOrders({
          variables: {
            filter: {
              ...initialOrderFilter,
              limit: paginationProp?.pageSize || LIMIT,
              type: orderTransferType,
              search: orderSearchTerm,
              dateRange: {
                from: finalWhereFilters?.createdAt?.from?.$d,
                to: finalWhereFilters?.createdAt?.to?.$d,
              },
              status:
                finalWhereFilters?.status?.length > 0
                  ? finalWhereFilters?.status
                  : undefined,

              orderPhase:
                finalWhereFilters?.type?.length > 0
                  ? finalWhereFilters?.type
                  : undefined,
            },
            sort: sortedInfo?.column
              ? {
                  field: sortedInfo?.field,
                  order:
                    sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
                }
              : initialOrderSort,
          },
        });
      }
    } catch (error) {
      return error;
    }
    setIsReportDamageLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsOrderLoading(true);
    transferOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          type: orderTransferType,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          orderPhase:
            whereFilter?.type?.length > 0 ? whereFilter?.type : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'deliveryType') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsOrderLoading(true);
    transferOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
          type: orderTransferType,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          orderPhase:
            whereFiltersCopy?.type?.length > 0
              ? whereFiltersCopy?.type
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(TRANSFER_ORDER_STATUS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'type':
          forEach(ORDER_TYPES, (item) => {
            optionsCopy?.push({ label: item, key: item });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (dataIndex === 'status') {
      return;
    }
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!filterIsEnd) {
      const target = event?.target;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const { Group } = Radio;
  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };
  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'returnedOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'type') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={ORDER_TYPE_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === DELIVERY_TYPE?.PAID?.value ||
      finalWhereFilters?.[dataIndex] === DELIVERY_TYPE?.FREE?.value ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const handleFulfillStatus = (value, record) => {
    setOrder(record);
    if (value === FULFILLMENT_STATUS?.FULFILLED?.value) {
      setIsFulfillPrompts(true);
    } else {
      setIsAddCommentModalOpen(true);
    }
  };

  const columns = [
    {
      title: 'TRANSFER ORDER ID',
      dataIndex: 'transferOrderNumber',
      key: 'transferOrderNumber',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) => record?.transferOrderNumber || '-',
    },
    {
      title: 'ORDER TYPE',
      dataIndex: 'type',
      key: 'type',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) => record?.type || '-',
      ...filterPopup('type', false),
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'productName',
      key: 'productName',
      ellipsis: true,
      align: 'left',
      width: 250,
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isToyPermission &&
          record?.orderItem?.toy?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.orderItem?.toy?.id}`,
          ),
        className: `${
          isToyPermission && record?.orderItem?.toy?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const toyData = record?.orderItem?.toy;
        return (
          <>
            <div className="table-data-with-id">{toyData?.name || '-'}</div>
            {toyData?.toyNumber && (
              <div className="font-size-12">({toyData?.toyNumber})</div>
            )}
          </>
        );
      },
    },
    {
      title: 'ORDER ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) => record?.orderItem?.order?.orderNumber || '-',
    },
    {
      title: 'RETURN ORDER ID',
      dataIndex: 'returnOrderId',
      key: 'returnOrderId',
      align: 'left',
      ellipsis: true,
      className: 'max-width-column',
      width: 150,
      render: (_, record) =>
        record?.returnOrderItem?.returnOrder?.returnOrderNumber || '-',
    },
    {
      title: 'ORDER STATUS',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const orderStatus = record?.orderItem?.order?.status;
        return (
          <Badge
            color={ORDER_STATUS?.[orderStatus]?.color}
            text={ORDER_STATUS?.[orderStatus]?.text}
          />
        );
      },
    },
    {
      title: 'FULFILLMENT STATUS',
      dataIndex: 'fulfillmentStatus',
      key: 'fulfillmentStatus',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) => {
        const orderItemStatus = record?.orderItem?.status;
        return orderItemStatus === FULFILLMENT_STATUS?.FULFILLED?.value ||
          orderItemStatus === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
          orderItemStatus === FULFILLMENT_STATUS?.CANCELLED?.value ? (
          <Badge
            color={FULFILLMENT_STATUS?.[orderItemStatus]?.color}
            text={FULFILLMENT_STATUS?.[orderItemStatus]?.text}
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: 'RETURN ORDER STATUS',
      dataIndex: 'returnOrderstatus',
      key: 'returnOrderstatus',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.returnOrderItem?.status
          ? RETURN_ORDER_ITEM_STATUS?.[record?.returnOrderItem?.status]?.label
          : '-',
    },
    {
      title: 'SENDER LIBRARY NAME',
      dataIndex: 'ownerLibraryName',
      key: 'ownerLibraryName',
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      width: 300,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.senderLibrary?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.senderLibrary?.id}/${record?.senderLibrary?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission &&
          record?.senderLibrary?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) =>
        record?.senderLibrary ? (
          <>
            <div className="table-data-with-id">
              {record?.senderLibrary?.name}
            </div>
            <div className="font-size-12">
              ({record?.senderLibrary?.libraryNumber})
            </div>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'RECEIVER LIBRARY NAME',
      dataIndex: 'receivingLibraryName',
      key: 'receivingLibraryName',
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      width: 300,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.receiverLibrary?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.receiverLibrary?.id}/${record?.receiverLibrary?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission &&
          record?.receiverLibrary?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) =>
        record?.receiverLibrary ? (
          <>
            <div className="table-data-with-id">
              {record?.receiverLibrary?.name}
            </div>
            <div className="font-size-12">
              ({record?.receiverLibrary?.libraryNumber})
            </div>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME),
      ...filterPopup('createdAt'),
    },
    {
      title: 'DAMAGE REQUESTED ON',
      dataIndex: 'requestedOn',
      key: 'requestedOn',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, record) =>
        record?.metaData?.requestedOn
          ? formatDate(record?.metaData?.requestedOn, DATE_FORMATS?.WITH_TIME)
          : '-',
    },
    {
      title: 'TRANSFER STATUS',
      dataIndex: 'transferStatus',
      key: 'transferStatus',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        // eslint-disable-next-line no-nested-ternary
        record?.status === TRANSFER_ORDER_STATUS.SENT.value
          ? record?.type === ORDER_TYPES.ORDER && !order?.requestGenerator?.id
            ? SENT_ORDER_LABEL
            : TRANSFER_ORDER_STATUS?.[record?.status]?.label
          : TRANSFER_ORDER_STATUS?.[record?.status]?.label,
      ...filterPopup('status', false),
    },
    {
      title: 'DAMAGE NOTE',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Tooltip title="View note" placement="top" zIndex={4}>
          <CommentIcon
            className="mr-16 pointer svg-icon"
            onClick={() => {
              setIsNotePrompts(true);
              setOrder(record);
            }}
          />
        </Tooltip>
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <>
          <div className="action-button">
            {
              // eslint-disable-next-line no-nested-ternary
              !transferTypeIn ? (
                record?.type === ORDER_TYPES.ORDER ? (
                  <>
                    <Button
                      type="primary"
                      size="small"
                      className="table-action-primary-btn"
                      onClick={() => {
                        setIsRequestPrompts(true);
                        setOrder(record);
                      }}
                      disabled={
                        ![
                          TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value,
                        ]?.includes(record?.status)
                      }
                    >
                      Sent
                    </Button>

                    <SelectComponent
                      size="small"
                      className="ml-16"
                      placeholder="Select Action"
                      showSearch={false}
                      style={{
                        width: '150px',
                      }}
                      value={
                        record?.status ===
                          TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value ||
                        record?.status === TRANSFER_ORDER_STATUS?.SENT?.value ||
                        record?.status ===
                          TRANSFER_ORDER_STATUS?.RECEIVED?.value
                          ? FULFILLMENT_STATUS.FULFILLED.value
                          : null
                      }
                      disabled={
                        record?.status !==
                          TRANSFER_ORDER_STATUS?.PENDING?.value ||
                        record?.type !== ORDER_TYPES.ORDER
                      }
                      options={NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS}
                      onChange={(value) => handleFulfillStatus(value, record)}
                      allowClear={false}
                    />
                  </>
                ) : (
                  <Button
                    type="primary"
                    size="small"
                    className="table-action-primary-btn"
                    onClick={() => {
                      setIsRequestPrompts(true);
                      setOrder(record);
                    }}
                    disabled={
                      ![TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value]?.includes(
                        record?.status,
                      )
                    }
                  >
                    Sent
                  </Button>
                )
              ) : (
                <>
                  <Button
                    type="primary"
                    size="small"
                    className="table-action-primary-btn"
                    onClick={() => {
                      setIsConfirmReturnPrompts(true);
                      setOrder(record);
                    }}
                    disabled={
                      ![TRANSFER_ORDER_STATUS?.SENT?.value]?.includes(
                        record?.status,
                      ) || !record?.senderLibrary
                    }
                  >
                    Received
                  </Button>
                  <div>
                    <SelectComponent
                      size="small"
                      className="ml-12"
                      style={{ width: '150px' }}
                      placeholder="Select Action"
                      showAction={['click']}
                      showSearch={false}
                      value={
                        record?.metaData?.isActualDamageRequestGenerated
                          ? 'REPORT_DAMAGE'
                          : null
                      }
                      options={RETURN_ORDER_TABLE_ACTIONS_OPTIONS?.filter(
                        (option) => option?.value === 'REPORT_DAMAGE',
                      )}
                      onChange={() => {
                        setOrder(record);
                        setIsReportDamageModalOpen(true);
                      }}
                      disabled={
                        record?.metaData?.isActualDamageRequestGenerated ||
                        record?.type !== ORDER_TYPES.ORDER ||
                        !record?.requestGenerator?.id ||
                        record?.orderItem?.status !==
                          FULFILLMENT_STATUS.UNFULFILLED?.value ||
                        record?.status !==
                          TRANSFER_ORDER_STATUS.RECEIVED?.value ||
                        !record?.receiverLibrary?.types?.includes(
                          LIBRARY_FACILITY_TYPE.WAREHOUSE.value,
                        ) ||
                        currentUser?.libraryId !== record?.receiverLibrary?.id
                      }
                      allowClear={false}
                    />
                  </div>
                </>
              )
            }
          </div>
        </>
      ),
    },
  ];

  const expandedRowRender = (data) => {
    const nestedData = [data];
    const nestedColumns = [
      {
        title: 'CREATOR NAME',
        dataIndex: 'creatorName',
        key: 'creatorName',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: `max-width-column`,
        onCell: (record) => ({
          onClick: () =>
            isUserPermission &&
            record?.creator?.id &&
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.creator?.id}/${record?.creator?.userNumber}`,
            ),
          className: `${
            isUserPermission && record?.creator?.id && 'pointer table-cell'
          }`,
        }),
        render: (_, record) => {
          const userData = record?.creator;
          return (
            <>
              {userData?.firstName || userData?.lastName ? (
                <div className="table-data-with-id">{`${
                  userData?.firstName ? userData?.firstName : ''
                } ${userData?.lastName ? userData?.lastName : ''}`}</div>
              ) : (
                '-'
              )}
              {userData?.userNumber ? (
                <div className="font-size-12">({userData?.userNumber})</div>
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        title: 'ACTION USER NAME',
        dataIndex: 'actionUserName',
        key: 'actionUserName',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: `max-width-column`,
        onCell: (record) => ({
          onClick: () =>
            isUserPermission &&
            record?.actionUser?.id &&
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.actionUser?.id}/${record?.actionUser?.userNumber}`,
            ),
          className: `${
            isUserPermission && record?.actionUser?.id && 'pointer table-cell'
          }`,
        }),
        render: (_, record) => {
          const userData = record?.actionUser;
          return (
            <>
              {userData?.firstName || userData?.lastName ? (
                <div className="table-data-with-id">{`${
                  userData?.firstName ? userData?.firstName : ''
                } ${userData?.lastName ? userData?.lastName : ''}`}</div>
              ) : (
                '-'
              )}
              {userData?.userNumber ? (
                <div className="font-size-12">({userData?.userNumber})</div>
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        title: 'REQUEST GENERATOR',
        dataIndex: 'requestGenerator',
        key: 'requestGenerator',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: `max-width-column`,
        onCell: (record) => ({
          onClick: () =>
            isUserPermission &&
            record?.requestGenerator?.id &&
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.requestGenerator?.id}/${record?.requestGenerator?.userNumber}`,
            ),
          className: `${
            isUserPermission &&
            record?.requestGenerator?.id &&
            'pointer table-cell'
          }`,
        }),
        render: (_, record) => {
          const userData = record?.requestGenerator;
          return (
            <>
              {userData?.firstName || userData?.lastName ? (
                <div className="table-data-with-id">{`${
                  userData?.firstName ? userData?.firstName : ''
                } ${userData?.lastName ? userData?.lastName : ''}`}</div>
              ) : (
                '-'
              )}
              {userData?.userNumber ? (
                <div className="font-size-12">({userData?.userNumber})</div>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];

    return (
      <CommonTable
        columns={nestedColumns}
        dataSource={nestedData || []}
        pagination={false}
        rowKey={(record) => record?.id}
        className="nested-table"
      />
    );
  };

  const locale = {
    emptyText: isEmptyOrderList ? '' : <span />,
  };
  const [disableMultiSelect, setDisableMultiSelect] = useState(null);

  const onSelectChange = (newSelectedRowKeys, record) => {
    setOrder(record?.[0]);
    if (record?.length > 0) {
      if (record?.[0]?.type === ORDER_TYPES.ORDER) {
        setDisableMultiSelect(ORDER_TYPES.RETURN);
      } else {
        setDisableMultiSelect(ORDER_TYPES.ORDER);
      }
    } else {
      setDisableMultiSelect(null);
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys, record) => onSelectChange(keys, record),
    getCheckboxProps: (record) => ({
      disabled: transferTypeIn
        ? ![TRANSFER_ORDER_STATUS?.SENT?.value]?.includes(record?.status) ||
          !record?.senderLibrary ||
          record?.type === disableMultiSelect
        : ![TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value]?.includes(
            record?.status,
          ) || record?.type === disableMultiSelect,
    }),
    hideSelectAll: !whereFilter?.type || whereFilter?.type?.length < 1,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const onMobileSelectChange = (key, record) => {
    setOrder(record);
    if (record) {
      if (record?.type === ORDER_TYPES.ORDER) {
        setDisableMultiSelect(ORDER_TYPES.RETURN);
      } else {
        setDisableMultiSelect(ORDER_TYPES.ORDER);
      }
    } else {
      setDisableMultiSelect(null);
    }

    setSelectedRowKeys((prev) => {
      if (prev?.includes(key)) {
        return prev?.filter((item) => item !== key);
      }
      return [...prev, key];
    });
  };
  const handleOrderScroll = () => {
    if (!isFetchMoreOrderLoading && !isOrderLoading) {
      const currentLength = orderList?.length;
      setIsFetchMoreOrderLoading(true);

      transferOrders({
        variables: {
          filter: {
            ...initialOrderFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            type: orderTransferType,
            search: orderSearchTerm,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOrderSort,
        },
      });
    }
  };

  const infiniteScrollRef = useInfiniteScroll(handleOrderScroll, isActive);

  const handleFilter = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setIsAllFilterModalOpen(false);
    setOrderList([]);
    setIsActive(true);
    const rangeObj = {
      from: values?.createdAt?.[0]?.startOf('day'),
      to: values?.createdAt?.[1]?.endOf('day'),
    };

    const filters = {
      status: values?.status,
      createdAt: values?.createdAt?.[1]
        ? { from: rangeObj?.from, to: rangeObj?.to }
        : null,
      orderPhase: values?.type?.length > 0 ? values?.type : null,
    };
    setWhereFilter(filters);
    setFinalWhereFilters(filters);

    transferOrders({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          type: orderTransferType,
          search: orderSearchTerm,
          dateRange: {
            from: rangeObj?.from,
            to: rangeObj?.to,
          },
          status: values?.status?.length > 0 ? values?.status : undefined,
          orderPhase: values?.type?.length > 0 ? values?.type : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setWhereFilter(filters);
    setFinalWhereFilters(filters);
  };

  const handleSelectAllMobile = (checked) => {
    if (checked) {
      setSelectedRowKeys((prev) => {
        const newIds = orderList
          ?.filter((record) =>
            transferTypeIn
              ? TRANSFER_ORDER_STATUS?.SENT?.value === record?.status
              : TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value === record?.status,
          )
          ?.map((data) => data.id);

        return Array.from(new Set([...prev, ...newIds]));
      });
    } else {
      setSelectedRowKeys([]);
    }
  };
  const isSelectAllDisabled = () => {
    if (transferTypeIn) {
      return !orderList?.some(
        (record) => record?.status === TRANSFER_ORDER_STATUS?.SENT?.value,
      );
    }
    return !orderList?.some(
      (record) =>
        record?.status === TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value,
    );
  };

  const findSelectableOrders = () => {
    if (transferTypeIn) {
      return orderList?.filter(
        (record) => TRANSFER_ORDER_STATUS?.SENT?.value === record?.status,
      )?.length;
    }

    return orderList?.filter(
      (record) =>
        TRANSFER_ORDER_STATUS?.READY_TO_SENT?.value === record?.status,
    )?.length;
  };

  return (
    <>
      <AddCommentModal
        form={form}
        isOrderAction
        isModalOpen={isAddCommentModalOpen}
        setIsModalOpen={setIsAddCommentModalOpen}
        isBtnLoading={isRequestLoadings}
        onFinish={(formValues) =>
          handleRequest(
            [order?.id],
            TRANSFER_ORDER_STATUS.UNFULFILLED.value,
            formValues,
          )
        }
      />

      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isFulfillPrompts}
        onOk={() =>
          handleRequest([order?.id], TRANSFER_ORDER_STATUS.READY_TO_SENT.value)
        }
        onCancel={() => setIsFulfillPrompts(false)}
        okButtonProps={{ loading: isRequestLoadings }}
      >
        Are you sure you want to
        <strong> Mark as fulfill </strong>
        this order item?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isConfirmReturnPrompts}
        onCancel={() => setIsConfirmReturnPrompts(false)}
        footer={[
          <div key="modal-actions">
            <Button
              onClick={() =>
                handleRequest([order?.id], TRANSFER_ORDER_STATUS.RECEIVED.value)
              }
              type="primary"
              loading={isRequestLoadings}
            >
              Received
            </Button>
            <Button
              onClick={() => {
                setIsConfirmReturnPrompts(false);
                setIsReportDamageModalOpen(true);
              }}
              type="primary"
            >
              Report Damage
            </Button>
            <Button
              onClick={() => setIsConfirmReturnPrompts(false)}
              className="cancel-btn"
            >
              Cancel
            </Button>
          </div>,
        ]}
      >
        Are you sure you want to
        <strong> Received / Report Damage </strong>
        this?
      </Modal>
      <ReportDamageModal
        isModalOpen={isReportDamageModalOpen}
        setIsModalOpen={setIsReportDamageModalOpen}
        isBtnLoading={isReportDamageLoading}
        onFinish={handleReportDamage}
        damageToyImage={damageToyImage}
        setDamageToyImage={setDamageToyImage}
        form={reportDamageForm}
        toyVideo={toyVideo}
        setToyVideo={setToyVideo}
        toyPreviewImage={toyPreviewImage}
        setToyPreviewImage={setToyPreviewImage}
        toyPreviewVideo={toyPreviewVideo}
        setToyPreviewVideo={setToyPreviewVideo}
        order={order}
        setOrder={setOrder}
      />
      <NoteModal
        title="Damage Note"
        order={order}
        isModalOpen={isNotePrompts}
        setIsModalOpen={setIsNotePrompts}
      />
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRequestPrompts}
        onOk={() =>
          handleRequest(
            [order?.id],
            !transferTypeIn
              ? TRANSFER_ORDER_STATUS.SENT.value
              : TRANSFER_ORDER_STATUS.RECEIVED.value,
          )
        }
        onCancel={() => setIsRequestPrompts(false)}
        okButtonProps={{ loading: isRequestLoadings }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!transferTypeIn ? 'Sent request' : 'Received request'}{' '}
        </strong>
        for this?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRequestsPrompts}
        onOk={() =>
          handleRequest(
            selectedRowKeys,
            !transferTypeIn
              ? TRANSFER_ORDER_STATUS.SENT.value
              : TRANSFER_ORDER_STATUS.RECEIVED.value,
          )
        }
        onCancel={() => setIsRequestsPrompts(false)}
        okButtonProps={{ loading: isRequestLoadings }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!transferTypeIn ? 'Sent requests' : 'Received requests'}{' '}
        </strong>
        for this?
      </Modal>
      <div className="d-flex justify-end align-center mb-16">
        <Button
          type="primary"
          disabled={!hasSelected}
          className="mr-8"
          onClick={() => setIsRequestsPrompts(true)}
        >
          {!transferTypeIn ? 'Sent Requests' : 'Received Requests'}
        </Button>
        <SearchComponent
          className={isDesktop ? 'search-box' : ''}
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>

      {!isDesktop && (
        <div className="d-flex justify-end align-center mb-16">
          {(whereFilter?.type || whereFilter?.type?.length > 1) && (
            <Checkbox
              indeterminate={
                selectedRowKeys?.length < findSelectableOrders() &&
                selectedRowKeys?.length > 0
              }
              disabled={isSelectAllDisabled()}
              checked={
                selectedRowKeys?.length > 0 &&
                selectedRowKeys?.length === findSelectableOrders() &&
                !isOrderLoading &&
                !isFetchMoreOrderLoading
              }
              onChange={(e) => {
                handleSelectAllMobile(e?.target?.checked);
              }}
            >
              {' '}
              Select All
            </Checkbox>
          )}
          <Badge dot={hasNonEmptyValueObj(finalWhereFilters)} size="small">
            <FilterIcon
              className="mobile-svg-icon ml-16"
              onClick={() => setIsAllFilterModalOpen(true)}
            />
          </Badge>
        </div>
      )}
      {isDesktop ? (
        <CommonTable
          locale={locale}
          columns={columns}
          data={orderList || []}
          loading={isOrderLoading}
          rowSelection={rowSelection}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record),
          }}
        />
      ) : (
        <div className="mobile-container-transfer no-scroll-bar">
          <TransferFilterOrderModal
            onFinish={handleFilter}
            isModalOpen={isAllFilterModalOpen}
            setIsModalOpen={setIsAllFilterModalOpen}
            finalWhereFilters={finalWhereFilters}
            setFinalWhereFilters={setFinalWhereFilters}
          />
          <TransferNestedDataModal
            order={order}
            setOrder={setOrder}
            isModalOpen={isTransferOrderItemModalOpen}
            setIsModalOpen={setIsTransferOrderItemModalOpen}
            setIsNotePrompts={setIsNotePrompts}
            setVisible={setIsRequestPrompts}
          />
          <TransferList
            orderList={orderList}
            transferTypeIn={transferTypeIn}
            setIsRequestPrompts={setIsRequestPrompts}
            setIsConfirmReturnPrompts={setIsConfirmReturnPrompts}
            infiniteScrollRef={infiniteScrollRef}
            isEmptyOrderList={isEmptyOrderList}
            isOrderLoading={isOrderLoading}
            setOrder={setOrder}
            setIsNotePrompts={setIsNotePrompts}
            isFetchMoreOrderLoading={isFetchMoreOrderLoading}
            setIsTransferOrderItemModalOpen={setIsTransferOrderItemModalOpen}
            onMobileSelectChange={onMobileSelectChange}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
            handleFulfillStatus={handleFulfillStatus}
            disableMultiSelect={disableMultiSelect}
            setIsReportDamageModalOpen={setIsReportDamageModalOpen}
          />
        </div>
      )}
    </>
  );
};

export default TransferOrderTable;
