import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Card, Form, Spin, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../../../common/constants';
import Portal from '../../../../../components/Portal';
import PaymentLinksForm from '../components/PaymentLinksForm';
import { UPDATE_PAYMENT_LINK } from '../graphql/Mutations';
import { GET_PAYMENT_LINK } from '../graphql/Queries';
import GoBackButton from '../../../../../components/GoBackButton';

const EditPaymentLink = () => {
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const [getPaymentLink, { data, loading, error }] = useLazyQuery(
    GET_PAYMENT_LINK,
    {
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        if (response?.personalizedOffer?.data) {
          form.setFieldsValue({
            ...response?.personalizedOffer?.data,
            plan: response?.personalizedOffer?.data?.plan?.id,
            expiryDate: dayjs(response?.personalizedOffer?.data?.expiryDate),
          });
        }
      },
      onError: (e) => {
        // console.error('Error fetching payment link:', e);
      },
    },
  );

  useEffect(() => {
    getPaymentLink({ variables: { where: { id } } });
  }, [getPaymentLink, id]);

  const [updatePaymentLink, { loading: updateLoading }] = useMutation(
    UPDATE_PAYMENT_LINK,

    {
      onCompleted: () => {
        history.push(`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`);
      },
      onError: () => {
        message.error('Failed to update payment link');
      },
    },
  );

  useEffect(() => {
    if (data?.personalizedOffer?.data) {
      form.setFieldsValue({
        ...data?.personalizedOffer?.data,
        plan: data?.personalizedOffer?.data?.plan?.id,
        expiryDate: dayjs(data?.personalizedOffer?.data?.expiryDate),
      });
    }
  }, [data, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await updatePaymentLink({
        variables: {
          where: {
            id,
          },
          data: {
            ...values,
            planId: values.plan,
            plan: undefined,
            expiryDate: values.expiryDate.toISOString(),
          },
        },
      });
    } catch (e) {
      // console.error('Error updating payment link:', e);
    }
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <Alert
          message="Error"
          description="There was an error fetching the payment link details. Please try again later."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}/view/${id}`}
        />
        <div className="portal-header">{MODULES.PAYMENT_LINKS}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          icon={<ArrowLeftOutlined />}
          onClick={() =>
            history.push(`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`)
          }
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="common-button ml-16"
          onClick={handleSubmit}
          loading={updateLoading}
        >
          Update
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <Button
          className="common-button"
          icon={<ArrowLeftOutlined />}
          onClick={() =>
            history.push(`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`)
          }
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="common-button ml-16"
          onClick={handleSubmit}
          loading={updateLoading}
        >
          Update
        </Button>
      </Portal>
      <Card className="full-height-without-nav">
        <PaymentLinksForm form={form} isEdit />
      </Card>
    </>
  );
};

export default EditPaymentLink;
