import { useLazyQuery } from '@apollo/client';
import { Col, DatePicker, Form, InputNumber, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { PLAN_NAMES, PLAN_TYPES } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import { SUBSCRIPTION_PLANS_ADMIN_SELECT } from '../../../../subscription/graphql/Queries';

const { price, whitespaceNotAllowed } = formValidatorRules;

const PaymentLinksForm = ({ form, isEdit, isView }) => {
  const [planList, setPlanList] = useState();
  const [isPlansLoading, setIsPlansLoading] = useState(false);

  const [subscriptionPlansAdmin] = useLazyQuery(
    SUBSCRIPTION_PLANS_ADMIN_SELECT,
    {
      onCompleted: (response) => {
        const filteredData = response?.subscriptionPlansAdmin?.data?.filter(
          (item) => item?.name !== PLAN_NAMES?.TRIAL?.value,
        );
        setPlanList(filteredData);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setIsPlansLoading(true);
    subscriptionPlansAdmin({
      variables: {
        filter: {
          isAllPlan: true,
        },
      },
    });
    setIsPlansLoading(false);
  }, []);

  const planListArray = planList?.map((plan) => ({
    key: plan?.id,
    label: `${PLAN_NAMES?.[plan?.name]?.key}-${
      PLAN_TYPES?.[plan?.type]?.key
    } (${plan?.subscriptionFeatures?.planVersion})`,
  }));

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      disabled={isView}
      className={`payment-links-form ${isView ? 'common-view-form' : 'form'}`}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Form.Item
            label="Plan"
            name="plan"
            rules={[
              {
                required: true,
                message: 'Please select plan',
              },
            ]}
          >
            <Select
              loading={isPlansLoading}
              placeholder="Select plan"
              onChange={(value) => {
                const selected = planList.find((plan) => plan.id === value);
                form.setFieldsValue({
                  actualPrice: selected?.price,
                });
              }}
            >
              {planListArray?.map((plan) => (
                <Select.Option key={plan.key} value={plan.key}>
                  {plan.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Form.Item label="Actual Price" name="actualPrice" disabled>
            <InputNumber
              disabled
              style={{ width: '100%' }}
              min={0}
              placeholder="Enter amount"
              formatter={(value) =>
                `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Form.Item
            label="Offer Price"
            name="offerPrice"
            rules={[
              price,
              {
                required: true,
                message: 'Please enter offer price',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              placeholder="Enter amount"
              formatter={(value) =>
                `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/₹\s?|(,*)/g, '')}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Form.Item
            label="Expiry Date"
            name="expiryDate"
            rules={[
              {
                required: true,
                message: 'Please select expiry date',
              },
            ]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              disabledDate={(current) =>
                current && current < dayjs().endOf('day')
              }
            />
          </Form.Item>
        </Col>

        {isEdit && (
          <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: 'Please select status',
                },
              ]}
            >
              <Select
                placeholder="Select status"
                options={[
                  { label: 'Active', value: true },
                  { label: 'Inactive', value: false },
                ]}
              />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item label="Notes" name="notes" rules={[whitespaceNotAllowed]}>
            <TextArea
              rows={3}
              allowClear
              placeholder="Enter Notes"
              showCount
              maxLength={700}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PaymentLinksForm;
