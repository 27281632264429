import { Card, Dropdown, Image, Tag } from 'antd';
import { filter } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as MoreIcon } from '../../../../../assets/svg/ellipsis-vertical-solid.svg';
import {
  DATE_FORMATS,
  DELIVERY_TYPE,
  RETURN_ORDER_CARD_ACTIONS_OPTIONS,
  RETURN_ORDER_ITEM_STATUS,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';

const AllReturnOrderCard = ({
  order,
  infiniteScrollRef,
  handleChange,
  setOrder,
  setIsContentMasterModalOpen,
  setIsAllReturnOrderItemModalOpen,
  setVisible,
  setToyImages,
}) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const handleMenuClick = (e) => {
    if (
      e?.key === RETURN_ORDER_CARD_ACTIONS_OPTIONS?.VIEW_CONTENT_LOGS?.value
    ) {
      setOrder(order);
      setIsContentMasterModalOpen(true);
    } else {
      handleChange(e?.key, order);
    }
  };

  const menuProps = {
    items: filter(RETURN_ORDER_CARD_ACTIONS_OPTIONS, (option) => {
      if (
        option.value ===
        RETURN_ORDER_CARD_ACTIONS_OPTIONS.VIEW_CONTENT_LOGS.value
      ) {
        return true;
      }
      if (order?.status === RETURN_ORDER_ITEM_STATUS.RETURN_CONFIRMED.value) {
        return option.value !== RETURN_ORDER_ITEM_STATUS.RETURN_CONFIRMED.value;
      }
      return option.value === RETURN_ORDER_ITEM_STATUS.RETURN_CONFIRMED.value;
    })?.map((option) =>
      option?.value ===
      RETURN_ORDER_CARD_ACTIONS_OPTIONS?.VIEW_CONTENT_LOGS?.value
        ? { label: option?.label, key: option?.value }
        : {
            label: option?.label,
            key: option?.value,
            disabled:
              order?.isLibraryDamageRequestGenerated ||
              (order?.status !== RETURN_ORDER_ITEM_STATUS?.RETURNED?.value &&
                order?.status !==
                  RETURN_ORDER_ITEM_STATUS?.RETURN_CONFIRMED?.value) ||
              (order?.returnOrder?.receivingLibraryId &&
                currentUser?.libraryId === order?.returnOrder?.library?.id),
          },
    ),
    onClick: handleMenuClick,
  };

  const pickedUpAttachments = order?.deliveryAttachments?.filter((attachment) =>
    [RETURN_ORDER_ITEM_STATUS?.PICKED_UP?.value, null]?.includes(
      attachment?.deliveryAttachmentsType,
    ),
  );

  const returnedAttachments = order?.deliveryAttachments?.filter(
    (attachment) =>
      attachment?.deliveryAttachmentsType ===
      RETURN_ORDER_ITEM_STATUS?.RETURNED?.value,
  );

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 mobile-card"
      title={
        <div className="overflow-scroll no-scroll-bar">
          {order?.returnOrder?.returnOrderNumber}
        </div>
      }
      extra={
        <div className="d-flex align-center">
          <Tag className="mr-16 ml-16">
            {RETURN_ORDER_ITEM_STATUS?.[order?.status]?.label}
          </Tag>
          <div onClick={(e) => e?.stopPropagation()} className="d-flex">
            <Dropdown
              getPopupContainer={(trigger) => trigger?.parentNode}
              menu={menuProps}
            >
              <MoreIcon className="pointer mobile-svg-icon" />
            </Dropdown>
          </div>
        </div>
      }
      ref={infiniteScrollRef}
      onClick={() => {
        setOrder(order);
        setIsAllReturnOrderItemModalOpen(true);
      }}
    >
      <div className="mb-8">
        <span className="mobile-card-label">Product ID: </span>
        <span>
          <>{order?.orderItem?.toy?.toyNumber}</>
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Product Name: </span>
        <span>
          <>{order?.orderItem?.toy?.name}</>
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Order ID: </span>
        <span>{order?.order?.orderNumber}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Delivery Type: </span>
        <span>{DELIVERY_TYPE?.[order?.order?.deliveryType]?.label}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">User ID: </span>
        <span>{order?.returnOrder?.user?.userNumber || 'No User ID'}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Delivery Partner Name: </span>
        <span>
          {order?.returnOrder?.deliveryPartner?.firstName ||
          order?.returnOrder?.deliveryPartner?.lastName
            ? `${
                order?.returnOrder?.deliveryPartner?.firstName
                  ? order?.returnOrder?.deliveryPartner?.firstName
                  : ''
              } ${
                order?.returnOrder?.deliveryPartner?.lastName
                  ? order?.returnOrder?.deliveryPartner?.lastName
                  : ''
              }`
            : 'No Delivery Partner Name'}
          {order?.returnOrder?.deliveryPartner?.userNumber ? (
            <div className="font-size-12">
              ({order?.returnOrder?.deliveryPartner?.userNumber})
            </div>
          ) : (
            ''
          )}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Pincode: </span>
        <span>{order?.returnOrder?.pincode?.pincode || 'No Pincode'}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Placed On: </span>
        <span>{formatDate(order?.createdAt, DATE_FORMATS?.WITH_TIME)}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Returned On: </span>
        <span>
          {formatDate(order?.deliveredOn, DATE_FORMATS?.REGULAR) ||
            'No Returned On'}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Picked-up Attachments: </span>
        <span>
          {pickedUpAttachments?.length > 0 ? (
            <Image
              preview={{
                visible: false,
              }}
              width={20}
              height={20}
              src={pickedUpAttachments?.[0]?.url}
              onClick={(e) => {
                e?.stopPropagation();
                setVisible(true);
                setToyImages(pickedUpAttachments);
              }}
              className="pointer"
            />
          ) : (
            <span>No Images</span>
          )}
          {pickedUpAttachments?.length > 1
            ? ` + ${pickedUpAttachments?.length - 1}`
            : ''}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Returned Attachments: </span>
        <span>
          {returnedAttachments?.length > 0 ? (
            <Image
              preview={{
                visible: false,
              }}
              width={20}
              height={20}
              src={returnedAttachments?.[0]?.url}
              onClick={(e) => {
                e?.stopPropagation();
                setVisible(true);
                setToyImages(returnedAttachments);
              }}
              className="pointer"
            />
          ) : (
            <span>No Images</span>
          )}
          {returnedAttachments?.length > 1
            ? ` + ${returnedAttachments?.length - 1}`
            : ''}
        </span>
      </div>
    </Card>
  );
};

export default AllReturnOrderCard;
