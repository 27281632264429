import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, message, Select } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import PaymentLinksForm from '../components/PaymentLinksForm';
import { CREATE_PERSONALIZED_OFFER } from '../graphql/Queries';

const { Option } = Select;

const CreatePaymentLink = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [createPersonalizedOffer, { loading }] = useMutation(
    CREATE_PERSONALIZED_OFFER,
    {
      onCompleted: () => {
        message.success('Personalized offer created successfully');
        history.replace(`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`);
      },
      onError: () => {
        message.error('Failed to create personalized offer');
      },
    },
  );

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await createPersonalizedOffer({
        variables: {
          data: {
            planId: values.plan,
            actualPrice: parseFloat(values.actualPrice),
            offerPrice: parseFloat(values.offerPrice),
            expiryDate: values.expiryDate.toISOString(),
            notes: values.notes,
          },
        },
      });
    } catch (error) {
      // Form validation error
    }
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`}
        />
        <div className="portal-header">{MODULES.PAYMENT_LINKS}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          icon={<ArrowLeftOutlined />}
          onClick={() =>
            history.push(`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`)
          }
        >
          Cancel
        </Button>
        <Button
          className="common-button ml-16"
          onClick={handleSubmit}
          loading={loading}
        >
          Create
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <Button
          className="common-button"
          icon={<ArrowLeftOutlined />}
          onClick={() =>
            history.push(`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`)
          }
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="common-button ml-16"
          onClick={handleSubmit}
          loading={loading}
        >
          Create
        </Button>
      </Portal>
      <Card className="full-height-without-nav">
        <PaymentLinksForm form={form} />
      </Card>
    </>
  );
};

export default CreatePaymentLink;
