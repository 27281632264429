import { CopyOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Alert, Button, Card, Form, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import PaymentLinksForm from '../components/PaymentLinksForm';
import { GET_PAYMENT_LINK } from '../graphql/Queries';

const ViewPaymentLink = () => {
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const [getPaymentLink, { data, loading, error }] = useLazyQuery(
    GET_PAYMENT_LINK,
    {
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        if (response?.personalizedOffer?.data) {
          form.setFieldsValue({
            ...response?.personalizedOffer?.data,
            plan: response?.personalizedOffer?.data?.plan?.id,
            expiryDate: dayjs(response?.personalizedOffer?.data?.expiryDate),
          });
        }
      },
      onError: (e) => {
        // console.error('Error fetching payment link:', e);
        // Consider using a logging library or custom error handler
      },
    },
  );

  useEffect(() => {
    getPaymentLink({ variables: { where: { id } } });
  }, [getPaymentLink, id]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(data?.personalizedOffer?.data?.link);
    message.success('Link copied to clipboard');
  };

  // useEffect(() => {
  //   if (data?.personalizedOffer?.data) {
  //     form.setFieldsValue({
  //       ...data?.personalizedOffer?.data,
  //       plan: data?.personalizedOffer?.data?.plan?.id,
  //       expiryDate: dayjs(data?.personalizedOffer?.data?.expiryDate),
  //       createdAt: dayjs(data?.personalizedOffer?.data?.createdAt),
  //     });
  //   }
  // }, [data, form]);

  if (loading) {
    return <LoaderComponent size="large" setHeight="60" spinning={loading} />;
  }

  if (error) {
    return (
      <div className="error-container">
        <Alert
          message="Error"
          description="There was an error fetching the payment link details. Please try again later."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}`}
        />
        <div className="portal-header">{MODULES.PAYMENT_LINKS}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          type="primary"
          className="common-button"
          icon={<CopyOutlined />}
          onClick={handleCopyLink}
        >
          Copy Link
        </Button>
        <Button
          className="common-button ml-16"
          size="middle"
          onClick={() =>
            history?.push(
              `${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}/edit/${id}`,
            )
          }
        >
          Edit Payment Link
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <Button
          type="primary"
          className="common-button"
          icon={<CopyOutlined />}
          onClick={handleCopyLink}
        >
          Copy Link
        </Button>
        <div className="header-btn-wrapper">
          <Button
            className="common-button ml-16"
            size="middle"
            onClick={() =>
              history?.push(
                `${ROUTES?.OFFERS}${ROUTES?.PAYMENT_LINKS}/edit/${id}`,
              )
            }
          >
            Edit Payment Link
          </Button>
        </div>
      </Portal>
      <Card className="full-height-with-nav" title="Payment Link Details">
        <div className="toy-form">
          <div className="admin-form">
            <PaymentLinksForm isView form={form} isEdit={false} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default ViewPaymentLink;
