import { DownloadOutlined, ExportOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, message } from 'antd';
import React, { useState } from 'react';
import {
  BULK_CHALLAN_DOWNLOAD_LIMIT,
  BULK_CHALLAN_DOWNLOAD_LIMIT_ERROR_MESSAGE,
  EXPORT_TYPES,
  MODULES,
} from '../../../../../common/constants';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import Portal from '../../../../../components/Portal';
import AllDeliveryTable from '../components/AllDeliveryTable';
import AllTabs from '../components/AllTabs';
import {
  BULK_CHALLAN_DOWNLOAD,
  ORDER_CHALLAN_EXPORT_ADMIN,
  ORDER_ITEM_STICKER_EXPORT_ADMIN,
  REQUEST_ORDER_EXPORT_ADMIN,
} from '../graphql/Mutations';

const AllDelivery = () => {
  const [form] = Form.useForm();
  const [exportFilter, setExportFilter] = useState();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportType, setExportType] = useState('');
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [bulkDownloadLoading, setBulkDownloadLoading] = useState(false);

  const [requestOrderExportAdmin] = useMutation(REQUEST_ORDER_EXPORT_ADMIN, {
    onError: () => {},
  });

  const [orderChallanExportAdmin] = useMutation(ORDER_CHALLAN_EXPORT_ADMIN, {
    onError: () => {},
  });

  const [bulkChallanDownload] = useMutation(BULK_CHALLAN_DOWNLOAD, {
    onError: () => {},
  });

  const [orderItemStickerExportAdmin] = useMutation(
    ORDER_ITEM_STICKER_EXPORT_ADMIN,
    {
      onError: () => {},
    },
  );

  const handleExport = async (values) => {
    setIsExportLoading(true);
    const res = await requestOrderExportAdmin({
      variables: {
        data: {
          type: EXPORT_TYPES?.ORDER,
        },
        filter: {
          search: exportFilter?.search,
          deliveryType: exportFilter?.deliveryType,
          orderIds: exportFilter?.orderNumber,
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
          deliveredOn: {
            from: exportFilter?.deliveredOn?.from?.$d,
            to: exportFilter?.deliveredOn?.to?.$d,
          },
          status:
            exportFilter?.status?.length > 0 ? exportFilter?.status : undefined,
          pincodeIds: exportFilter?.pincodes,
          deliveryPartnerIds: exportFilter?.deliveryPartnerName,
        },
      },
    });
    if (res?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsExportLoading(false);
  };

  const handleChallanExport = async (values) => {
    setIsLoading(true);
    const response = await orderChallanExportAdmin({
      variables: {
        data: {
          type: EXPORT_TYPES?.ORDER_CHALLANS,
        },
        filter: {
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
        },
      },
    });
    if (response?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsLoading(false);
  };

  const handleBulkChallanDownload = async () => {
    if (selectedOrders?.length > BULK_CHALLAN_DOWNLOAD_LIMIT) {
      message.error(BULK_CHALLAN_DOWNLOAD_LIMIT_ERROR_MESSAGE);
    } else {
      setBulkDownloadLoading(true);
      try {
        await bulkChallanDownload({
          variables: {
            data: {
              orderIds: selectedOrders,
              type: EXPORT_TYPES?.BULK_ORDER_CHALLANS,
            },
          },
        });
        setBulkDownloadLoading(false);
        setSelectedOrders([]);
      } catch (error) {
        setBulkDownloadLoading(false);
      }
    }
  };

  const handleStickerExport = async (values) => {
    setIsLoading(true);
    const response = await orderItemStickerExportAdmin({
      variables: {
        data: {
          type: EXPORT_TYPES?.ORDER_STICKERS,
        },
        filter: {
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
        },
      },
    });
    if (response?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsLoading(false);
  };

  function getTitle(type) {
    switch (type) {
      case EXPORT_TYPES.ORDER_STICKERS:
        return 'Sticker Export';
      case EXPORT_TYPES.ORDER_CHALLANS:
        return 'Challan Export';
      default:
        return 'Export';
    }
  }

  function getFinishHandler(type) {
    switch (type) {
      case EXPORT_TYPES.ORDER_STICKERS:
        return handleStickerExport;
      case EXPORT_TYPES.ORDER_CHALLANS:
        return handleChallanExport;
      default:
        return handleExport;
    }
  }

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.ALL}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {selectedOrders?.length > 0 && (
          <Button
            className="common-button ml-16"
            size="middle"
            icon={<DownloadOutlined />}
            onClick={handleBulkChallanDownload}
            loading={bulkDownloadLoading}
          >
            Download Challans
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => {
            setExportType(EXPORT_TYPES?.ORDER_CHALLANS);
            setIsModalOpen(true);
          }}
        >
          Challan Export
        </Button>
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => {
            setExportType(EXPORT_TYPES?.ORDER_STICKERS);
            setIsModalOpen(true);
          }}
        >
          Sticker Export
        </Button>
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => {
            setExportType(EXPORT_TYPES?.ORDER);
            setIsModalOpen(true);
          }}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        {selectedOrders?.length > 0 && (
          <Button
            className="common-button ml-16"
            size="middle"
            icon={<DownloadOutlined />}
            onClick={handleBulkChallanDownload}
            loading={bulkDownloadLoading}
          >
            Download Challans
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => {
            setExportType(EXPORT_TYPES?.ORDER_CHALLANS);
            setIsModalOpen(true);
          }}
        >
          Challan Export
        </Button>
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => {
            setExportType(EXPORT_TYPES?.ORDER_STICKERS);
            setIsModalOpen(true);
          }}
        >
          Sticker Export
        </Button>
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => {
            setExportType(EXPORT_TYPES?.ORDER);
            setIsModalOpen(true);
          }}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <ExportDateRangeModal
        form={form}
        title={getTitle(exportType)}
        onFinish={getFinishHandler(exportType)}
        loadings={isLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <AllTabs>
        <div className="tab-table">
          <AllDeliveryTable
            exportFilter={exportFilter}
            setExportFilter={setExportFilter}
            selectedOrders={selectedOrders}
            setSelectedOrders={setSelectedOrders}
          />
        </div>
      </AllTabs>
    </>
  );
};

export default AllDelivery;
