import { Empty } from 'antd';
import React from 'react';
import ModalComponent from '../../../../../components/ModalComponent';
import TransferItemCard from './TransferItemCard';

const TransferNestedDataModal = ({
  order,
  setOrder,
  isModalOpen,
  setIsModalOpen,
  setIsNotePrompts,
  setVisible,
}) => {
  const isEmptyList =
    !order?.actionUser?.id &&
    !order?.requestGenerator?.id &&
    !order?.creator?.id;

  return (
    <ModalComponent
      open={isModalOpen}
      onCancel={() => {
        setOrder({});
        setIsModalOpen(false);
      }}
      title={`${order?.transferOrderNumber} -Transfer Order ID`}
      footer={null}
      wrapClassName="full-screen-modal"
    >
      <div className="mobile-full-screen-container no-scroll-bar">
        {!isEmptyList && (
          <TransferItemCard
            record={order}
            setIsNotePrompts={setIsNotePrompts}
            setVisible={setVisible}
          />
        )}
        {isEmptyList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
      </div>
    </ModalComponent>
  );
};

export default TransferNestedDataModal;
